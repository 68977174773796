@import "./../../assets/style/root";

.footer {
  background-color: rgba(196, 196, 196, 0.1);
  padding-top: 5.125rem;
  &-title {
    font-weight: bold;
    color: #000000;
  }
  &__logo {
    height: 1.75rem;
    margin-bottom: 1.9375rem;
    path {
      fill: #1e2227;
    }
  }

  &__review {
    padding-bottom: 3.5rem;

    &-cards {
      display: flex;
      flex-direction: row;
    }

    &-title {
      margin-bottom: 1.5rem;
    }

    &-card {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 32px;
      padding: 8px 12px;
      border: 1px solid #d6d6d6;
      border-radius: 6px;
      background-color: white;
      margin-right: 7px;
    }
  }

  &__info {
    padding-top: 2.625rem;
    margin-bottom: 3rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
  }
  &__contacts {
    display: flex;
    flex-direction: column;
    &-title {
      margin-bottom: 2.75rem;
    }

    &-mail {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.5rem;

      &_title {
        font-size: 0.875rem;
        margin-bottom: 7px;
      }

      &_link {
        color: #929292;
        font-size: 12px;
        text-decoration: underline;
      }
    }

    &-adress {
      font-size: 0.875rem;
      line-height: 156.5%;
      color: #000000;
      max-width: 14.1875rem;
      margin-bottom: 2.625rem;
    }
    &-tell,
    &-email {
      font-size: 0.875rem;
      line-height: 136%;
      color: #000000;
    }
    &-tell {
      margin-bottom: 1.5rem;
    }
  }
  &__fabric {
    display: flex;
    flex-direction: column;
    &-title {
      margin-bottom: 2.75rem;
    }
    &-link {
      font-size: 0.875rem;
      line-height: 136%;
      color: #000000;
      margin-bottom: 0.875rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__help {
    display: flex;
    flex-direction: column;
    &-title {
      margin-bottom: 2.75rem;
    }
    &-link {
      font-size: 0.875rem;
      line-height: 136%;
      color: #000000;
      margin-bottom: 0.875rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__social {
    display: flex;
    flex-direction: column;
    padding-right: 6.25rem;
    &-title {
      margin-bottom: 2.75rem;
    }
    &-link {
      font-size: 0.875rem;
      line-height: 136%;
      color: #000000;
      margin-bottom: 14px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__time--mobile {
    display: none;
  }
  &__links {
    display: flex;
    flex-direction: column;
  }
  &__time {
    display: flex;
    flex-direction: column;
    padding-bottom: 3.5rem;
    &-title {
      margin-bottom: 1.5rem;
    }
    &-work {
      font-size: 0.875rem;
      color: #000000;
    }
  }
  &__consultation {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 1.75rem;
    padding-bottom: 3.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-title {
      margin-bottom: 1.375rem;
    }
    &-text {
      display: flex;
      flex-direction: column;
      max-width: 32.625rem;
      font-size: 1.125rem;
      line-height: 150%;
      color: #000000;
      font-weight: $font-text;
    }
    &-box {
      display: flex;
      margin-top: 2rem;
      width: 23.125rem;
      height: 3.75rem;
      margin-bottom: 20px;
      .consultation-tel__input {
        width: 310px;
        border: 1px solid #c7c7c7;
        padding: 1.25rem 1.875rem;
        box-sizing: border-box;
        font-size: 1.125rem;
        color: rgba(0, 0, 0, 0.2);
        transition: border-color 0.3s;
        &.error {
          border-color: red;
        }
        &::-webkit-input-placeholder {
          font-size: 1.125rem;
          color: rgba(0, 0, 0, 0.2);
        }
      }
      .consultation-tel__btn {
        width: 3.75rem;
        height: 3.75rem;
        background-color: #000;
        &:disabled {
          opacity: 0.6;
        }
      }
    }
  }
  &-main {
    padding-top: 3.375rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    &__year,
    &__link {
      font-size: 0.75rem;
      color: #000000;
    }
    &__docs--mobile {
      display: none;
    }
    &__link--desktop {
      display: none;
    }
    &__logo {
      display: flex;
      flex-direction: column;
      &-img {
        margin-bottom: 0.4375rem;
      }
      &-title {
        text-align: center;
        font-size: 8px;
        color: #000000;
      }
    }
  }

  @media (max-width: 500px) {
    padding-top: 4.375rem;
    &-title {
      font-size: 0.875rem;
      margin-bottom: 0;
      width: 60%;
    }

    &__review {
      &-title {
        margin-bottom: 1.5rem;
      }

      &-card {
        margin-bottom: 7px;
      }

      &-cards {
        justify-content: flex-end;
      }
    }

    &__logo {
      width: 7.5rem;
      height: 1.25rem;
    }
    &__info {
      display: flex;
      flex-direction: column;
    }
    &__contacts {
      margin-bottom: 3.75rem;
      &-title {
        margin-bottom: 2.3125rem;
      }
    }
    &__time {
      display: none;
    }
    &__time--mobile {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4.6875rem;
    }
    &__fabric {
      flex-direction: row;
      margin-bottom: 2.875rem;
    }
    &__links {
      display: flex;
      flex-direction: column;
    }
    &__help {
      flex-direction: row;
      margin-bottom: 2.875rem;
    }
    &__social {
      flex-direction: row;
      padding-right: 0;
    }

    &__consultation {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      flex-direction: column;
      &-title {
        margin-bottom: 1.625rem;
      }
      &-text {
        font-size: 0.875rem;
        & span:first-child {
          margin-bottom: 0.75rem;
        }
      }
      &-tel {
        margin-top: 1.75rem;
        width: 100%;
        .consultation-tel__input {
          width: 230px;
          padding: 1.25rem 0.875rem;
        }
      }
    }
    &-main {
      padding-top: 2.125rem;
      padding-bottom: 1.375rem;
      flex-wrap: wrap;
      &__docs {
        display: none;
      }
      &__docs--mobile {
        width: 100%;
        display: block;
        margin-bottom: 1.625rem;
        & a:first-child {
          margin-bottom: 1.125rem;
        }
      }
      &__year {
        display: flex;
        align-items: center;
        order: 2;
      }
    }
  }
}
