@import "src/assets/style/keyframes";

.modal-cart {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 8;
  background-color: #fff;
  padding: 60px 30px 30px;
  width: 340px;
  max-width: 100%;
  box-sizing: border-box;
  animation: fadeIn 0.5s linear;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  opacity: 0;
  visibility: hidden;

  &__title {
    font-size: 18px;
    margin-bottom: 10px;
    white-space: nowrap;
  }

  &__list {
    border-top: 1px solid #BDBDBD;
    border-bottom: 1px solid #BDBDBD;
    padding-top: 40px;
    padding-bottom: 30px;
    margin-bottom: 60px;
    height: max-content;
    max-height: 190px;
    overflow: auto;
  }

  &__item {
    display: flex;
    position: relative;
    margin-bottom: 28px;
    animation: fadeIn 0.5s linear;

    &:last-child {
      margin-bottom: 0;
    }

    &-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-right: 25px;
    }

    &-title {
      font-size: 14px;
    }

    &-price {
      font-size: 14px;
    }

    &-btn {
      &--close {
        position: absolute;
        width: 14px;
        height: 14px;
        top: 2px;
        right: 0;

        &:after,
        &:before {
          content: '';
          display: block;
          width: 14px;
          height: 1px;
          background-color: #000000;
          position: absolute;
          top: 6px;
          left: 0;
          transform: rotate(-45deg);
        }

        &:before {
          transform: rotate(45deg);
        }
      }
    }

    &-img {
      width: 100px;
      height: 74px;
      margin-right: 20px;
      object-fit: cover;
    }
  }

  &__link {
    width: 100%;
    height: 66px;
    background-color: #000000;
    color: #FFFFFF;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  &__close {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 15px;
    right: 30px;
    &:after,
    &:before {
      content: '';
      display: block;
      width: 30px;
      height: 1px;
      background-color: #000000;
      transform: rotate(45deg);
      position: absolute;
      top: 14px;
      left: 0;
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

@media (max-width: 500px) {
  .modal-cart {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    box-sizing: border-box;
    overflow: auto;
    &__list {
      max-height: initial;
      overflow: hidden;
    }
  }
}
