@import './../../assets/style/root';

.collection-page {
  padding-top: 1.75rem;
  padding-bottom: 11.875rem;
  &__title {
    text-align: center;
    color: #1E2227;
    margin-bottom: 100px;
  }
  &__list {
    display: flex;
    width: 588px;
    margin: 0 auto;
    position: relative;
    margin-bottom: 5rem;
  }
  &__list-btn {
    border-bottom: 1px solid #E6E6E6;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
  }

  &__btn {
    font-family: $font-text;
    font-size: 0.875rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $border;
    transition: color .3s,  border-color .3s;
    border-bottom: 1px solid transparent;
    padding: 0 20px 8px;
    margin-bottom: -1px;
    flex-grow: 1;
    white-space: nowrap;

    &.active {
      box-sizing: border-box;
      color: #000000;
      border-color: #000000;
    }
  }
  &__line-active {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 5.5625rem;
    height: 1px;
    background-color: #000;
    transition: left .3s;
    padding: 0 1.25rem;
  }
  &__list-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__item {
    animation: fadeIn .5s linear;
    &-img {
      margin-bottom: 1.375rem;
      width: 100%;
      height: auto;
      object-fit: contain;
    }
    &-name {
      font-size: 1.125rem;
      color: #1E2227;
    }
    &:nth-child(odd) {
      // margin-right: 6.25rem;
      width: 51%;
      .collection-page__item-img {
        // width: 38.125rem;
        width: 100%;
        // height: 25rem;
      }
    }
    &:nth-child(even) {
      padding-top: 18.75rem;
      width: 40%;
      .collection-page__item-img {
        //width: 30.5rem;
        width: 100%;
        //height: 20rem;
      }
    }
  }
}

@media (max-width: 500px) {
  .collection-page {
    padding-bottom: 4rem;
    &__title {
      margin-bottom: 70px;
    }
    &__list {
      max-width: 100%;
    }
    &__btn {
      padding: 0 10px 8px;
      font-size: 0.75rem;
    }
    &__item {
      margin-bottom: 40px;
      &:nth-child(odd) {
        width: 100%;
        .collection-page__item-img {
          height: auto;
        }
      }
      &:nth-child(even) {
        padding-top: 0;
        width: 100%;
        .collection-page__item-img {
          height: auto;
        }
      }
    }
  }
}