.about-page__slider {
  margin-bottom: 126px;
  overflow: visible;
  
  .about-page__years {
    margin-bottom: 100px;
    position: relative;
    overflow: visible;
    &:after {
      content: '';
      display: block;
      width: 300%;
      height: 1px;
      background-color: #C3C3C3;
      position: absolute;
      bottom: 0;
      left: -100%;
      z-index: -1;
    }
  }
  .about-page__line {
    width: 200px;
    height: 1px;
    background-color: #000000;
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .about-page__year {
    font-weight: normal;
    font-size: 64px;
    opacity: 0.3;
    color: transparent;
    white-space: nowrap;
    border-bottom: 1px solid transparent;
    -webkit-text-stroke-color: #ABABAB;
    -webkit-text-stroke-width: 1px;
    transition: all .3s;
    cursor: pointer;
    &.active {
      opacity: 1;
      border-color: #000000;
      color: #000000;
      -webkit-text-stroke-color: transparent;
      -webkit-text-stroke-width: 0;
      position: relative;

    }
  }
  .about-page__content {
    display: flex;
    justify-content: space-between;
    padding-left: 100px;
    animation: fadeIn .5s linear;
    &-wrapper {
      width: calc(40% - 100px);
      margin-right: 100px;
    }
    &-title {
      font-size: 36px;
      color: #000000;
      font-family: "Playfair Display";
      margin-bottom: 40px;
    }
    &-desc {
      max-width: 100%;
      line-height: 150%;
      font-size: 18px;
      color: #000000;
    }
    &-img {
      //width: 590px;
      height: 26vw;
      width: 50%;
      object-fit: cover;
      image-rendering: -webkit-optimize-contrast;
      flex-grow: 1;
    }

  }
}

.about-page__box {
  min-height: 300px;
  transition: height .5s;
}

@media (max-width: 1200px) {
  .about-page__slider {
    .about-page {
      &__content {
        padding-left: 0;
        flex-direction: column;
        &-wrapper {
          width: 100%;
        }
        &-desc {
          max-width: 100%;
          margin-bottom: 40px;
        }
        &-img {
          width: 100%;
          object-fit: cover;
        }
      }
      &__years {
        margin-bottom: 50px;
      }
    }
  }
}

@media (max-width: 500px) {

  .about-page__slider {
    margin-bottom: 60px;
    .about-page {
      &__years {
        margin-bottom: 46px;
      }
      &__year {
        font-size: 24px;
        padding: 0 15px;
      }
      &__content {
        flex-direction: column;
        padding-left: 0;
        &-wrapper {
          width: 100%;
        }
        &-title {
          margin-bottom: 30px;
        }
        &-desc {
          font-size: 14px;
          width: 100%;
          margin-bottom: 30px;
        }
        &-img {
          width: 100%;
          height: 286px;
        }
      }
    }
  }
}