.order {
  &__list {
    width: 350px;
    height: max-content;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 40px;
    border: 1px solid #BDBDBD;
    position: sticky;
    top: 20px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    &-img {
      width: 102px;
      height: 74px;
    }
    &-info {
      width: 100px;
      display: flex;
      flex-direction: column;
    }
    &-name {
      font-size: 14px;
      color: #000000;
      margin-bottom: 16px;
      font-weight: 600;
      flex-grow: 1;
      &:first-child {
        margin-bottom: 0;
      }

    }
    &-price {
      font-size: 14px;
      color: #000000;
    }
  }
  &__total {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #BDBDBD;
    &-date {
      font-size: 14px;
      color: #BDBDBD;
    }
    &-price {
      font-size: 18px;
      color: #000000;
    }
  }
}

@media (max-width: 768px) {
  .order {
    &__list {
      width: 36%;
      padding: 20px;
    }
  }

}


@media (max-width: 500px) {
  .order {
    &__list {
      max-width: 100%;
      padding: 20px;
      overflow: hidden;
      margin-bottom: 22px;
      width: 100%;
    }
    &__item {
      flex-direction: column;
      &-img {
        width: 100%;
        height: 162px;
        margin-bottom: 20px;
      }
      &-info {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
      }
    }
    &__total {
      padding-top: 20px;
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: -1px;
        left: -100%;
        width: 300%;
        height: 1px;
        background-color: #BDBDBD;
      }
    }
  }
}