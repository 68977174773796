@import "src/assets/style/keyframes";

.success-modal {
  width: 490px;
  max-width: 100%;
  padding: 50px;
  box-sizing: border-box;
  position: fixed;
  top: calc(50% - 250px);
  left: calc(50% - 245px);
  background-color: #fff;
  z-index: 5;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  opacity: 0;
  visibility: hidden;

  &__img {
    width: 186px;
    height: 186px;
    display: block;
    margin: 0 auto 60px;

  }

  &__title {
    font-family: "Playfair Display";
    text-align: center;
    margin-bottom: 60px;
  }

  &__subtitle {
    font-size: 18px;
    color: #929292;
    text-align: center;
  }

  &__close {
    position: absolute;
    top: 50px;
    right: 34px;
    width: 24px;
    height: 24px;

    &:after,
    &:before {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background-color: #000000;
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      left: 0;
    }

    &:before {
      transform: rotate(-45deg);
    }
  }
}

@media (max-width: 500px) {
  .success-modal {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}