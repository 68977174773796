@import "src/assets/style/keyframes";

.button {
  &__share {
    position: relative;
    display: inline-block;
    margin-top: 14px;
    width: 100%;

    &-list {
      display: none;
      width: 100%;
      position: absolute;
      background-color: #ffffff;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      overflow-y: scroll;
      height: 22vh;
    }

    &-item {
      width: inherit;
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: flex;
      align-items: center;
      &:hover {
        background-color: #ddd;
      }
    }
  }
  &__item {
    width: 100%;
    height: 72px;
    background-color: #f1f1f1;
    box-sizing: border-box;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    color: #000000;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &:hover {
      background-color: #000000;
      color: #ffffff;
    }

    &-svg {
      margin-right: 13%;
      margin-left: 25%;
    }
  }
}

.button__share:hover .button__share-list {
  display: block;
}

