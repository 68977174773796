.info {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.icon {
  margin-right: 15px;
  align-self: flex-start;
  svg {
    width: 16px;
    height: 16px;

  }
}
.text {
  color: #000000;
  opacity: 0.5;
  font-size: 12px;
}