@import './../../assets/style/root';


.products-model {
  padding-top: 2.5rem;
  padding-bottom: 14.6875rem;

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    color: #1E2227;
    text-align: center;
    margin-bottom: 4.375rem;
  }

  &__list {
    // max-width: 860px;
    width: 71.7%;

    &-title {
      font-size: 1.5rem;
      color: #000;
      margin-bottom: 5.625rem;
    }

    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__item {
    width: 45%;
    animation: fadeIn .5s linear;
    &-img {
      height: 260px;
      width: 100%;
      margin-bottom: 1.25rem;
      object-fit: cover;
    }

    &-name {
      font-size: 1.125rem;
      color: #000;
    }
    &-subtitle {
      color: #000000;
      font-size: 14px;
      margin-bottom: 10px;
    }

    &:nth-child(3n+2) {
      width: 41%;
      padding-top: 7.5rem;
      margin-bottom: 2.8125rem;

      .products-model__item-img {
        height: 82.2%;
      }
    }

    &:nth-child(3n + 3) {
      width: 57%;
      margin: 0 auto;
      margin-bottom: 3.75rem;

      .products-model__item-img {
        height: 86%;
      }
    }
  }

  @media (max-width: 1200px) {
    &__wrapper {
      flex-direction: column;
    }
    &__list {
      width: 100%;
      order: 2;
    }
    .aside-menu {
      order: 1;
      max-width: 100%;
    }
  }

  @media (max-width: 500px) {
    padding-top: 1.5rem;
    padding-bottom: 3.625rem;
    &__list-title {
      margin-bottom: 2.25rem;
      font-size: 1.125rem;
    }
    .aside-menu {
      margin-bottom: 44px;
    }
    &__item {
      margin-bottom: 3.75rem;

      &-img {
        margin-bottom: 0.5rem;
      }

      &-name {
        font-size: 0.875rem;
      }

      &:nth-child(odd) {
        width: 100%;
        margin-right: 0;
        padding-top: 0;

        &-img {
          height: 15.125rem;
        }
      }

      &:nth-child(even) {
        width: 100%;
        height: 100%;
        padding-top: 0;
        margin-bottom: 3.75rem;
        padding-left: 4.375rem;
        margin-right: 0;

        &-img {
          height: 11rem;
        }
      }
    }
  }
}


@media (max-width: 500px) {
  .products-model {
    &__list {
      width: 100%;
    }

    &__item {
      &-img {
        height: auto;
      }
    }
  }
}