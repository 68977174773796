.success-order {
  margin: 100px 0;
  text-align: center;
  animation: fadeIn .5s linear;
  &__title {
    margin-bottom: 40px;
  }
  &__subtitle {
    margin-bottom: 26px;
  }
  &__desc {
    font-size: 18px;
    margin-bottom: 100px;
  }
  &__link {
    width: 280px;
    height: 66px;
    background-color: #000000;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
}

@media (max-width: 500px) {
  .success-order {
    margin: 50px 0;
    &__title {
      font-size: 48px;
    }
    &__subtitle {
      font-size: 24px;
    }
    &__desc {
      font-size: 14px;
    }
  }
}