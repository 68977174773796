.dealers {
  background-color: #F4F4F4;
  &__desc {
    font-size: 24px;
    line-height: 1;
    margin-bottom: 20px;
  }
  &__title {
    margin-top: 44px;
    margin-bottom: 50px;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    height: 100vh;
  }
  .footer {
    background-color: transparent;
  }
  &__pos {
    width: max-content;
  }
  &__wrap {
    width: 60vw;
  }
  &__shops {
    width: 40%;
    padding: 50px;
    box-sizing: border-box;
    overflow-y: auto;
  }
  &__search {
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    border: none;
    border-bottom: 1px solid #DDDDDD;
    background-color: transparent;
    padding: 8px 8px;
    margin-bottom: 20px;
    font-family: "Inter", sans-serif;
    transition: border-bottom-color 0.3s;
    &:hover {
      border-bottom-color: #000000;
    }
  }

  .shop {
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 10px;
    }
    &__city  {
      margin-bottom: 10px;
      transition: opacity 0.3s;
      cursor: pointer;
      opacity: 0.7;
      &:hover {
        opacity: 0.4;
      }
    }
    &__info {
      margin-bottom: 10px;
    }
    &__name {
      margin-bottom: 5px;
      font-weight: 600;
      font-size: 14px;
    }
    &__desc {
      font-size: 14px;
      margin-bottom: 5px;
    }
    &__check-map {
      font-size: 14px;
      text-decoration: underline;
      transition: opacity 0.3s;
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
    }

  }
  & ymaps {
    filter: grayscale(1);
    & .title {
      font-size: 16px;
      font-weight: bold;
    }
    & .ymaps-2-1-79-balloon__layout {
      z-index: 1;
    }
  }

}



@media (max-width: 500px) {
  .dealers {
    background-image: none;
    &__title {
      margin: 45px 0;
    }
    &__wrapper {
      flex-direction: column;
      margin-bottom: 30px;
      height: max-content;
    }
    &__shops {
      width: 100%;
      padding: 0;
      overflow: auto;
    }
    &__search {
      box-sizing: border-box;
    }
    &__wrap {
      display: none;
    }
    &__wrap {
      position: absolute;
      left: 0;
    }

    .shop {
      &__check-map {
        display: none;
      }
    }
  }
}