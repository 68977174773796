@import "src/assets/style/keyframes";
@import "../../assets/style/mixins";

.vacancy-modal {
  width: 580px;
  max-width: 100%;
  padding: 76px 46px 56px;
  box-sizing: border-box;
  background-color: #fff;
  position: fixed;
  z-index: 5;
  top: calc(50% - 400px);
  left: calc(50% - 290px);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  opacity: 0;
  visibility: hidden;
  &__close {
    position: absolute;
    top: 35px;
    right: 35px;
    width: 24px;
    height: 24px;

    &:after,
    &:before {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background-color: #000000;
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      left: 0;
    }

    &:before {
      transform: rotate(-45deg);
    }
  }

  &__title {
    font-family: Playfair Display;
    text-align: center;
    margin-bottom: 30px;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__item {
    position: relative;
    margin-bottom: 20px;
    @extend %inputHover;

  }

  &__label {
    font-size: 18px;
    color: #828282;
    opacity: 0.2;
    position: absolute;
    top: calc(50% - 10px);
    right: 18px;
    z-index: -1;
    transition: opacity 0.3s;
  }

  &__politic {
    display: flex;
    align-items: center;

    input[type="checkbox"]:checked + label:before,
    input[type="checkbox"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      border: 1px solid #C4C4C4;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    input[type="checkbox"]:checked + label:after,
    input[type="checkbox"]:not(:checked) + label:after {
      content: "";
      position: absolute;
      left: 2px;
      top: 3px;
      width: 10px;
      height: 5px;
      border-radius: 1px;
      border-left: 4px solid #000000;
      border-bottom: 4px solid #000000;
      transform: rotate(-45deg);
    }

    input[type="checkbox"]:not(:checked) + label:after {
      opacity: 0;
    }

    input[type="checkbox"]:checked + label:after {
      opacity: 1;
    }

  }

  &__comments {
    width: 100%;
    box-sizing: border-box;
    padding: 24px 18px;
    margin-top: 30px;
    font-size: 18px;
    color: #bdbdbd;
    height: 142px;
    border: 1px solid #CCCCCC;
    margin-bottom: 80px;
    resize: none;
    transition: border-color .3s;
    @extend %commentsHover;

    &::-webkit-input-placeholder {
      color: #BDBDBD;
      transition: color .3s;
    }
  }

  &__check-title {
    position: relative;
    padding-left: 30px;
    line-height: 20px;
    font-size: 12px;
    color: #BDBDBD;
    cursor: pointer;
    & > a {
      color: #BDBDBD;
      font-size: 12px;
      transition: color .3s;
      line-height: 1;
      &:hover {
        color: #000000;
      }
    }
  }

  &__input {
    width: 100%;
    height: 60px;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    font-size: 18px;
    padding-left: 18px;
    padding-right: 18px;
    text-align: left;
    transition: border .3s;

    &.error {
      border: 1px solid #FF0000;
    }

    &::-webkit-input-placeholder {
      color: #BDBDBD;
    }

    &:focus + label {
      opacity: 0;
    }
  }

  &__submit {
    width: 280px;
    height: 66px;
    background-color: #202020;
    font-size: 14px;
    color: #fff;
    margin: 0 auto;
    transition: background-color .3s;
    &:hover {
      background-color: #535252;
    }
  }
}

@media (max-width: 500px) {
  .vacancy-modal {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow: auto;
    padding: 30px;

    &__input {
      font-size: 14px;

      &::-webkit-input-placeholder {
        font-size: 14px;
      }
    }

    &__label {
      font-size: 14px;
    }

    &__comments {
      font-size: 14px;
      margin-bottom: 40px;

      &::-webkit-input-placeholder {
        font-size: 14px;
      }
    }
  }
}



