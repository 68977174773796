@import "../../assets/style/mixins";
@import "../../assets/style/root";

.modal-color-cloth {
  width: 370px;
  max-width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  background-color: #fff;
  padding: 30px 40px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: visible;
  &__cloth {
    margin-bottom: 75px;
    &-img {
      display: block;
      width: 300px;
      max-width: 300px;
      height: auto;
      object-fit: contain;
      image-rendering: -webkit-optimize-contrast;
      margin-bottom: 16px;
      cursor: pointer;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
    }
    &-title {
      font-size: 18px;
    }
  }

  &__close {
    width: 32px;
    height: 32px;
    position: relative;
    cursor: pointer;
    margin-bottom: 30px;
    &:after,
    &:before {
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      background-color: #000000;
      transform: rotate(45deg);
    }
    &:before {
      transform: rotate(-45deg);
    }
  }
  &.active {
    opacity: 1;
    visibility: visible;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 44px;
    height: 30px;
    position: absolute;
    left: calc(50% - 22px);
    top: calc(100% + 34px);
    z-index: 3;
    display: flex;
    justify-content: space-between;

    &::after {
      content: "";
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #808080;
      position: absolute;
      top: 0;
      right: 28px;
    }
  }
  .swiper-button-prev {
    left: calc(50% - 56px);
  }
  .swiper-button-next {
    transform: rotate(180deg);
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 1;

    &::after {
      opacity: 0;
    }
  }
  &__slider {
    overflow: visible;
    &-img {
      object-fit: contain;
      width: 280px;
      max-width: 100%;
      height: 220px;
      max-height: 100%;
      cursor: pointer;
    }
    &-title {
      font-size: 18px;
      color: #000000;
      margin-bottom: 10px;
    }
  }
}

.modal__img-detail {
  width: 900px;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  max-width: 100%;
  z-index: 7;
  transform: translate(-50%, -50%);
  & img {
    width: 100%;
    height: 100%;
  }
}

.modal__close {
  width: 24px;
  height: 24px;
  @extend %close-btn;
  right: 3%;
  top: 3%;
  &:after,
  &:before {
    height: 2px;
    right: calc(50% - 12px);
  }
}

@media (max-width: 1280px) {
  .modal-color-cloth {
    width: 700px;
  }
}

@media (max-width: 500px) {
  .modal-color-cloth {
    height: 100%;
    width: 100%;
    top: 0;
    .swiper-button-next,
    .swiper-button-prev {
      top: 340px;
    }
    &__slider-img {
      height: 370px;
    }
    &__slider-title {
      font-size: 24px;
    }
  }
}
