@import "../../../assets/style/root.scss";
@import "./../../../assets/style/mixins";

.goods-card {
  padding-top: 80px;
  padding-bottom: 100px;
  display: flex;
  justify-content: space-between;
  &__imgs {
    width: 49%;
    display: flex;
    flex-direction: column;
  }
  &__desc {
    font-size: 14px;
    margin-bottom: 20px;
  }
  &__content {
    width: 100%;
    height: 280px;
    //background-color: #f4f4f4;
    box-sizing: border-box;
    &-img {
      width: 100%;
      height: 100%;
      image-rendering: -webkit-optimize-contrast;
      object-fit: contain;
      cursor: pointer;
    }
  }
  &__size {
    display: flex;
    flex-direction: column;
    &-name {
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 10px;
      &:nth-child(2) {
        margin-bottom: 30px;
      }
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
    }
    &-btn {
      @extend %optionsBtn;
    }
  }
  &__sizes {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  &__text {
    font-size: 14px;
  }

  &__img {
    width: 100%;
    min-height: 360px;
    margin-bottom: 40px;
    object-fit: cover;
    cursor: pointer;

    &:nth-child(2n + 2) {
      //height: 580px;
      width: 66%;
      // margin: 0 auto;
      // margin-right: 0;
      align-self: flex-end;
    }

    &:nth-child(3n + 3) {
      //height: 325px;
      width: 83%;
      // margin: 0 auto;
      // margin-right: 0;
      align-self: flex-end;
    }
  }

  &__info {
    width: 42%;
    padding-right: 100px;
    box-sizing: border-box;
    position: relative;

    &-wrapper {
      top: 20px;
      position: sticky;
    }

    &-category {
      font-size: 14px;
      color: #000000;
      margin-bottom: 14px;
    }

    &-title {
      color: #1e2227;
      margin-bottom: 24px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    position: relative;
    margin-bottom: 25px;

    &.active {
      &:after {
        display: none;
      }

      .goods-card__parameters {
        padding-top: 20px;
      }
    }

    &-title {
      font-weight: bold;
      font-size: 14px;
      color: #000000;
    }

    &-btn {
      width: 100%;
      height: 20px;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 9px;
      right: 0;
      width: 10px;
      height: 2px;
      background-color: #000000;
      z-index: -1;
    }

    &:after {
      transform: rotate(90deg);
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
  }

  &__link {
    width: max-content;
    font-size: 14px;
    color: #000000;
    padding-bottom: 4px;
    margin-bottom: 24px;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #000000;
    }
  }

  &__price {
    margin-bottom: 30px;
    font-size: 36px;
    color: #1e2227;
  }

  &__order-btn {
    width: 100%;
    height: 72px;
    border: 1px solid #000000;
    box-sizing: border-box;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    color: #000000;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    &--size {
      margin-top: 30px;
    }
    &:hover {
      background-color: #000000;
      color: #ffffff;
    }
  }

  &__order-inlineBtn {
    font-size: 14px;
    color: #000000;
    border-bottom: 1px solid black;

    &::after {
      content: url(../../../assets/img/icons/download.png);
      margin-left: 5px;
    }
  }

  &__parameters {
    display: block;
    height: 0;
    overflow: hidden;
    transition: height 0.3s, padding-top 0.3s;
    padding-top: 0;
  }

  .parameters {
    margin-top: 34px;
    margin-bottom: 55px;

    &__clothes {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-self: flex-start;
      margin-bottom: 22px;

      &-btn {
        width: 50%;
        height: 50px;
        border: 1px solid #e7e7e7;
        box-sizing: border-box;
        color: #bdbdbd;
        font-size: 18px;
        margin-left: -1px;
        margin-bottom: -1px;
        transition: color 0.3s, background-color 0.3s;
      }

      .active {
        color: #000000;
        background-color: #f5f5f5;
      }
    }

    &__category {
      display: flex;
      margin-bottom: 30px;

      &-item {
        margin-right: 24px;
        font-size: 14px;
        color: #000000;
        opacity: 0.2;
        transition: opacity 0.3s;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          text-decoration: underline;
          opacity: 1;
        }
      }
    }

    .active {
      color: #000000;
      opacity: 0.8;
    }

    &__colors {
      margin-bottom: 40px;

      &-title {
        font-size: 14px;
        color: #000000;
        font-weight: 400;
        margin-bottom: 18px;
      }

      &-list {
        //width: 336px;
        display: flex;
        flex-wrap: wrap;
      }

      &-item {
        width: 66px;
        height: 66px;
        border-radius: 50%;
        margin-right: 20px;
        margin-bottom: 10px;
        overflow: hidden;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        transition: opacity 0.3s;

        & > img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 24px;
        }

        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 1px solid #bfbfbf;
        }
      }

      &-img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    &__wood-colors {
      &-title {
        font-size: 14px;
        color: #000000;
        font-weight: 400;
        margin-bottom: 18px;
      }

      &-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }

      &-item {
        width: 66px;
        height: 66px;
        border-radius: 50%;
        margin-right: 20px;
        margin-bottom: 10px;
        overflow: hidden;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        transition: opacity 0.3s;

        & > img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 24px;
        }

        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 1px solid #bfbfbf;
        }
        //@media (max-width: 1293px) {
        //  //&:nth-child(4n + 4) {
        //  //  margin-right: 0;
        //  //}
        //  &:nth-child(5n + 5) {
        //    margin-right: 15px;
        //  }
        //}
      }

      &-img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    &__download {
      margin-top: 40px;
      margin-bottom: 25px;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .cart__desc {
    margin-bottom: 50px;

    &-item {
      display: flex;
      margin-bottom: 8px;
    }
  }

  .desc__title {
    width: 120px;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #333333;
    opacity: 0.4;
  }

  .desc__text {
    font-size: 14px;
  }

  .equipment {
    &__btns {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-self: flex-start;
      margin-bottom: 22px;

      .equipment__btn {
        width: 33%;
        height: auto;
        min-height: 50px;
        padding: 5px;
        border: 1px solid #e7e7e7;
        box-sizing: border-box;
        color: #bdbdbd;
        font-size: 18px;
        margin-left: -1px;
        margin-bottom: -1px;
      }

      .active {
        color: #000000;
        background-color: #f5f5f5;
      }
    }

    &__model {
      margin-bottom: 10px;
    }

    &__size {
      margin-bottom: 30px;
    }

    &__model,
    &__size {
      font-size: 18px;
      color: #000000;
    }
  }
}

.goods-collection {
  @extend %cursor-slider;
  padding-top: 100px;
  padding-bottom: 120px;

  &__title {
    font-family: Playfair Display;
    font-size: 36px;
    color: #1e2227;
    margin-bottom: 68px;
  }
}

@media (max-width: 1200px) {
  .goods-card {
    &__info {
      padding-right: 0;
    }
    .parameters__wood-colors-list,
    .parameters__colors-list {
      width: 100%;
    }
  }
}

@media (max-width: 500px) {
  .goods-card {
    padding-top: 60px;
    flex-direction: column;
    padding-bottom: 0;

    &__imgs {
      width: 100%;
    }

    &__img {
      height: 200px;
      width: 91%;
      margin-left: 0;
      margin-right: auto;
      margin-bottom: 60px;

      &:nth-child(1) {
        max-height: 200px;
        object-fit: contain;
        margin-bottom: 0;
      }

      &:nth-child(2n + 2) {
        height: 390px;
        width: 76%;
        padding-left: 90px;
        margin-right: initial;
      }

      &:nth-child(3n + 3) {
        height: 220px;
        width: 91%;
      }
    }

    &__info {
      width: 100%;
      padding-right: 0;
    }

    .parameters__colors-list,
    .parameters__wood-colors-list {
      width: 100%;
    }
    .parameters__wood-colors-list {
      justify-content: flex-start;
    }
    .parameters__colors-item,
    .parameters__wood-colors-item {
      &:nth-child(4n + 4) {
        margin-right: 0;
      }
    }
    .parameters__wood-colors-item {
      margin-right: 15px;
    }
  }

  .goods-collection {
    padding-top: 80px;
    padding-bottom: 60px;
    overflow-x: hidden;

    &__title {
      font-size: 24px;
      max-width: 220px;
      margin-bottom: 44px;
      line-height: 126.3%;
    }
  }
}
