.how-order {
  background-color: #F4F4F4;
  .footer {
    background-color: #F4F4F4;
  }
  &__title {
    margin-top: 100px;
    margin-bottom: 50px;
  }
  &__desc {
    font-size: 18px;
    color: #6D6D6D;
    margin-bottom: 100px;
    width: 535px;
    max-width: 100%;
    line-height: 30px;

  }

  &__online {
    display: flex;
    justify-content: space-between;
    //flex-wrap: wrap;
    margin-bottom: 100px;
    &-wrapper {
      margin-right: 48px;
      width: 550px;
      max-width: 100%;
    }
    &-title {
      width: 450px;
      max-width: 100%;
      line-height: 64px;
      margin-bottom: 50px;
    }
    &-desc {
      font-size: 18px;
      color: #6D6D6D;
      margin-bottom: 50px;
      line-height: 1.5;
    }
    &-link {
      width: 280px;
      height: 66px;
      background-color: #000000;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
    }
    &-img {
      width: 590px;
      max-width: 100%;
      object-fit: cover;
    }
  }
  &__pay {
    margin-bottom: 150px;
    &-title {
      margin-bottom: 50px;
    }
    &-list {
      display: flex;
      flex-direction: column;
    }
    &-item {
      margin-bottom: 52px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-type {
      font-size: 24px;
      margin-bottom: 20px;
    }
    &-desc {
      width: 450px;
      max-width: 100%;
      line-height: 1.5;
      font-size: 18px;
      color: #6D6D6D;
    }
  }
  &__feedback {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 100px;
    border-top: 1px solid #000000;
    &-wrapper {
      margin-right: 160px;
    }
    &-title {
      width: 314px;
      margin-bottom: 40px;
    }
    &-desc {
      color: #6D6D6D;
      font-size: 18px;
      width: 452px;
      max-width: 100%;
      line-height: 1.5;
    }
  }
}
@media(max-width: 1280px) {
  .how-order__online-img {
    width: 570px;
  }
}
@media (max-width: 1200px) {
  .how-order {
    &__feedback {
      padding-top: 30px;
      &-wrapper {
        margin-right: 0;
        width: 100%;
        margin-bottom: 60px;
      }
      &-title {
        width: 100%;
      }
    }
    &__online {
      margin-bottom: 60px;
      &-wrapper {
        width: 45%;
        margin-right: 0;
      }
      &-img {
        width: 48%;

      }
    }
  }
}

@media(max-width: 500px) {
  .how-order {
    background-color: #fff;
    .footer {
      background-color: #fff;
    }
    &__title {
      margin-top: 45px;
      font-size: 36px;
      margin-bottom: 15px;
    }
    &__desc {
      font-size: 14px;
      margin-bottom: 24px;
    }

    &__online {
      padding-top: 60px;
      margin-bottom: 50px;
      flex-wrap: wrap;
      &-wrapper {
        width: 100%;
      }
      &-title {
        font-size: 36px;
        line-height: 1;
        margin-bottom: 25px;
      }
      &-desc {
        font-size: 14px;
        margin-bottom: 35px;
      }
      &-img {
        width: 100%;
        margin-bottom: 44px;
      }
      &-link {
        width: 100%;
      }
    }
    &__pay {
      margin-bottom: 65px;
      &-type {
        font-size: 18px;
      }
      &-desc {
        font-size: 14px;
      }
    }
    &__feedback {
      padding-top: 30px;
      &-wrapper {
        width: 100%;
        margin-right: 0;
        margin-bottom: 60px;
      }
      &-title {
        font-size: 36px;
        margin-bottom: 24px;
        white-space: nowrap;
        width: 100%;
      }
      &-desc {
        font-size: 14px;
        width: 100%;
      }
    }
  }
}