.collection-group__list-wrapper {
  padding-top: 130px;
  margin-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.collection-group {
  &__item {
    width: 49%;
    height: 100%;
    &-img {
      width: 100%;
      height: 47%;
      margin-bottom: 1.375rem;
      object-fit: cover;
      image-rendering: -webkit-optimize-contrast;
    }
    &-name {
      font-size: 1.125rem;
      color: #1E2227;
    }

    &:nth-child(odd) {
      width: 48%;
    }
    &:nth-child(even) {
      padding-top: 16.75rem;
      width: 41%;
    }

    //&:nth-child(even) {
    //  margin-top: 12.5rem;
    //  width: 32%;
    //  //height: 37.5rem;
    //  padding-right: 100px;
    //  .collection-group__item-img {
    //    //width: 30.5rem;
    //    width: 100%;
    //    //height: 20rem;
    //    //height: calc(100% - 40px);
    //  }
    //}
    //&:nth-child(3n + 3) {
    //  padding-left: 100px;
    //  box-sizing: border-box;
    //  width: 49%;
    //  margin-top: 0;
    //  .collection-group__item-img {
    //    width: 100%;
    //    height: 72%;
    //  }
    //}
  }
}

@media (max-width: 500px) {
  .collection-group {
    &__list {
      &-wrapper {
        padding-top: 60px;
        margin-bottom: 60px;
        flex-direction: column;
      }
    }
    &__item {
      width: 100%;
      margin-bottom: 60px;
      padding-right: 50px;
      box-sizing: border-box;
      &:nth-child(odd) {
        width: 100%;
      }
      &:nth-child(even) {
        padding-top: 0;
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;
        height: auto;
        padding-left: 70px;
        padding-right: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &-img {
        height: auto;
      }
    }
  }
}