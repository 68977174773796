.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  &__title {
    font-size: 144px;
    color: #000000;
    line-height: 144px;
    margin-bottom: 18px;
  }
  &__subtitle {
    width: 400px;
    max-width: 100%;
    font-size: 18px;
    color: #959595;
    margin-bottom: 60px;
    text-align: center;
  }
  &__btn {
    background-color: #202020;
    width: 240px;
    height: 64px;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}