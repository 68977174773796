@import "assets/style/root";

.App {
}

input {
  border-radius: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-text;
  font-size: 16px;
  cursor: default;
}

.container {
  width: calc(100% - 80px);
  margin: 0 auto;
  max-width: 1440px;
}

.rubble {
  font-family: 'PT Sans', serif;
}


.fadeIn {
  animation: fadeIn .3s linear forwards;
}

.fadeOut {
  animation: fadeOut .3s ease forwards;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .4);
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s, visibility .3s;

  &--img {
    z-index: 6;
    cursor: pointer;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }
}


.news-detail {
  &__content {
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    flex-direction: column;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__img {
    width: 50%;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  &__desc {
    margin: 100px 0;
  }
}

@media (max-width: 500px) {
  .App {
    overflow: hidden;
  }

  .news-detail {
    &__content {
      flex-direction: column;
    }

    &__text {
      width: 100%;
    }

    &__img {
      width: 100%;
    }
  }
}