@import "./../../assets/style/root";

.discount-model {
  padding-top: 2.5rem;
  padding-bottom: 14.6875rem;
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__title {
    color: #1e2227;
    text-align: center;
    margin-bottom: 4.375rem;
  }
  &__list {
    // max-width: 860px;
    width: 71.7%;
    &-title {
      font-size: 1.5rem;
      color: #000;
      margin-bottom: 5.625rem;
    }
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  &__text {
    display: flex;
    justify-content: space-between;
  }
  &__price {
    text-align: end;
    .price-sale {
      font-size: 12px;
      color: #afafaf;
      text-decoration: line-through;
      margin-bottom: 6px;
    }
    .price-no-sale {
      font-size: 14px;
      color: #000000;
    }
  }
  &__item {
    width: 45%;
    &-img {
      height: auto;
      width: 100%;
      margin-bottom: 1.25rem;
      object-fit: cover;
    }
    &-name {
      font-size: 1.125rem;
      color: #000;
    }
    // &:nth-child(3n + 3) {
    //   width: 100%;
    //   &-img {
    //     width: 60%;
    //   }
    // }
    // &:nth-child(3n + 2) {
    //   background-color: red;
    // }

    &:nth-child(3n + 2) {
      width: 41%;
      padding-top: 7.5rem;
      margin-bottom: 2.8125rem;
      .discount-model__item-img {
        height: 82.2%;
      }
    }
    // &:nth-child(4n) {
    //   width: 24.25rem;
    //   margin-right: 7.625rem;
    //   &-img {
    //     height: 16.25rem;
    //   }
    // }
    &:nth-child(3n + 3) {
      width: 57%;
      margin: 0 auto;
      margin-bottom: 3.75rem;
      .discount-model__item-img {
        height: 86%;
      }
    }
  }
  @media (max-width: 1200px) {
    &__list {
      order: 2;
      width: 100%;
    }
    .aside-menu {
      order: 1;
      max-width: 100%;
      margin-bottom: 44px;
    }
    &__wrapper {
      flex-direction: column;
    }
  }

  @media (max-width: 500px) {
    padding-top: 1.5rem;
    padding-bottom: 3.625rem;
    &__list-title {
      margin-bottom: 2.25rem;
      font-size: 1.125rem;
    }

    &__item {
      margin-bottom: 3.75rem;
      &-img {
        margin-bottom: 0.5rem;
      }
      &-name {
        font-size: 0.875rem;
      }
      &:nth-child(odd) {
        width: 100%;
        margin-right: 0;
        padding-top: 0;
        &-img {
          height: 15.125rem;
        }
      }
      &:nth-child(even) {
        width: 100%;
        height: 100%;
        padding-top: 0;
        margin-bottom: 3.75rem;
        padding-left: 4.375rem;
        margin-right: 0;
        &-img {
          height: 11rem;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .discount-model {
    &__list {
      width: 100%;
    }
    &__item {
      &-img {
        height: auto;
      }
      margin-bottom: 46px;
      &:nth-child(even) {
        margin-bottom: 46px;
      }
      &:nth-child(3n + 3) {
        .discount-model__item-img {
          height: auto;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
