@import '../../../assets/style/root.scss';
@import '../../../assets/style/keyframes.scss';

.promo {
  height: 100vh;
  min-height: 45rem;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(20, 18, 18, 0.5), rgba(20, 18, 18, 0.5));
  }
  &__video {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    width: 100%;
  }
  &__box {
    padding-top: 25vh;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__title {
    max-width: 100%;
    width: 52.75rem;
    margin-bottom: 3.25rem;
    color: #FFFFFF;
    text-align: center;
    position: relative;
    padding-bottom: 3.5rem;
    z-index: 3;
    & span {
      font-style: italic;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: calc(50% - 2.75rem);
      height: 1px;
      width: 5.5rem;
      background-color: rgba(255,255,255, .56);
    }
  }
  &__subtitle {
    width: 16rem;
    max-width: 100%;
    height: 10.56vh;
    margin-bottom: 4.375rem;
    line-height: 1.5rem;
    text-align: center;
    color: #FFFFFF;
    z-index: 3;

    font-family: $font-title;
    font-size: 1.125rem;
  }
  &__scroll {
    height: 12.25vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 2.875rem;
    margin: 0 auto;
    z-index: 3;
    &-line {
      height: 58px;
      width: 1px;
      background-color: rgba(255,255,255, .2);
      margin-bottom: 14px;
      position: relative;
      overflow: hidden;
      &::after {
        content: '';
        height: 17px;
        width: 1px;
        background-color: #fff;
        position: absolute;
        top: 0;
        transition: top .3s, opacity .3s;
        animation: scroll-down 3s infinite;
      }
    }
    &-title {
      font-family: $font-text;
      font-size: 0.875rem;
      line-height: 0.875rem;
      text-align: center;
      color: #FFFFFF;
    }
  }
}

@media (max-width: 1200px) {
  .promo {
    &__title {
      font-size: 2.25rem;
      font-weight: 400;
      padding-bottom: 1.5rem;
      margin-bottom: 2.625rem;
    }
    &__subtitle {
      font-family: $font-title;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.1875rem;
    }
  }
}