@import "src/assets/style/root";

.vacancy-work {
  &__text {
    margin-top: 75px;
    margin-bottom: 190px;
    display: flex;
    justify-content: space-between;
  }
  &__title {
    font-weight: normal;
    font-size: 24px;
    color: #333333;
  }
  &__desc {
    max-width: 468px;
    font-size: 18px;
    color: #6D6D6D;
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    font-family: $font-text;
    & a {
      text-decoration: none;
      margin-top: 25px;
      font-size: 14px;
      color: #000000;
      transition: opacity .3s;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  &__way {
    padding: 0 100px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 160px;
    &-title {
      font-size: 24px;
      color: #000000;
      display: flex;
      align-items: center;
      flex-grow: 1;
      &--active {
        color: #333333;
      }
      & span {
        
      }
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #D9D9D9;
      }
      &:nth-child(2n + 2) {
        & span {
          margin: 0 40px;
        }
        
      }
      &:first-child {
        flex-grow: 0;
        & span {
          margin-left: 0;
          margin-right: 20px;
          max-width: 162px;
        }
        &:before {
          display: none;
        }
      }
      &:last-child {
        & span {
          margin-right: 0;
          margin-left: 20px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .vacancy-work {
    &__way {
      padding: 0;
    }
  }
}

@media (max-width: 500px) {
  .vacancy-work {
    &__text {
      margin-top: 60px;
      margin-bottom: 64px;
      flex-direction: column;
    }
    &__title {
      font-size: 24px;
      margin-bottom: 20px;
    }
    &__way {
     display: none;
    }
  }
}