.dealers-shop {
  margin-bottom: 150px;
  &__title {
    font-size: 24px;
    margin-bottom: 50px;
  }
  &__item {
    width: 25%;
    font-size: 14px;
    color: #828282;
    margin-bottom: 14px;
    transition: color .3s;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
  }
}