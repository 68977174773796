@import 'root';

// Стилизация скролбра
%scrollbar::-webkit-scrollbar {
  width: .5%;
  min-width: 8px;
}

%scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

%scrollbar::-webkit-scrollbar-thumb {
  border-left: 1px solid #F2F2F2;
  background: $border;
}

// Крестик для кнопки закрытия
%close-btn {
  position: absolute;
  right: 5%;
  top: 3%;
  height: 20px;
  width: 20px;

  &::after,
  &::before {
    transition: background-color .3s;
    content: '';
    width: 100%;
    height: 1px;
    background-color: $gen;
    position: absolute;
    top: 50%;
    right: calc(50% - 10px);
  }

  &::after {
    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(135deg);
  }
}

/*Кнопка меню*/
/*В разметке обязательно должна иметь внутренний span */
/*<button className="burger"><span></span></button>*/
%burger {
  overflow: visible;

  & > span {
    position: relative;
    margin-top: 7px;
    margin-bottom: 7px;
    display: block;
    width: 22px;

    &,
    &::after,
    &::before {
      background-color: $gen;
      height: 1px;
      transition: background-color .3s, transform .3s;
    }

    &::after,
    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: 5px;
      width: 100%;
    }

    &::before {
      top: -5px;
    }
  }

  &:hover span {

    &,
    &::after,
    &::before {
      background-color: $accent;
    }
  }

  &.active {
    & > span {
      background-color: transparent;
      transition: none;

      &::after {
        top: 0;
        transform: rotate(-135deg);
      }

      &::before {
        top: 0;
        transform: rotate(135deg);
      }
    }
  }
}
%cursor-slider {
  cursor: url(./../img/icons/product__cursor.svg) 50 50, auto;
}

%inputHover {
  &:hover {
    &  label {
      color: #000000;
      opacity: 1;
    }

    & input {
      border: 1px solid #000000;
    }
  }
}
%inputCartHover {
  &:hover {
    & + label {
      color: #000000;
      opacity: 1;
    }
    border: 1px solid #000000;
  }
}
%commentsHover {
  &:hover {
    border-color: #000000;
    &::-webkit-input-placeholder {
      color: #000000;
    }
  }
}

%optionsBtn {
  width: 33%;
  height: auto;
  min-height: 50px;
  padding: 5px;
  border: 1px solid #E7E7E7;
  box-sizing: border-box;
  color: #BDBDBD;
  font-size: 18px;
  border-right: 0;
  transition: color .3s, background-color .3s;
  overflow: hidden;

  &.active {
    color: #000000;
    font-size: 18px;
    background-color: #F5F5F5;
  }
  &:hover {
    color: #000000;
  }

  &:nth-child(3n + 3) {
    border-right: 1px solid #E7E7E7;
  }

  &:last-child {
    border-right: 1px solid #E7E7E7;
  }

  &:nth-child(3n + 4) {
    border-top: 0;
  }
  &:nth-child(3n + 5) {
    border-top: 0;
  }
  &:nth-child(3n + 6) {
    border-top: 0;
  }
}