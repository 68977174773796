.header-cart {
  margin-top: 8px;
  margin-bottom: 58px;
  &__text {
    height: auto;
    background-color: #f5f5f5;
    padding: 5px 20px 5px 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    font-size: 18px;
    max-width: 100%;
    width: 70%;
    & span {
      margin-right: 5px;
      &:after {
        content: '+';
        margin-left: 5px;
      }
      &:last-child {
        &:after {
          content: '';
        }
      }

    }

  }
  &__price {
    font-size: 18px;
  }

  &__way {
    padding: 0 100px;
    display: flex;
    justify-content: space-between;
    margin: 50px 0;
    &-title {
      font-size: 24px;
      color: #d6d6d6;
      display: flex;
      align-items: center;
      flex-grow: 1;
      transition: color .3s;
      &.active {
        color: #333333;
        &::before {
          background-color: #333333;
        }
      }
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        transition: background-color .3s;
        background-color: #D9D9D9;
      }
      &:nth-child(2n + 2) {
        & span {
          margin: 0 40px;
        }
        
      }
      &:first-child {
        flex-grow: 0;
        & span {
          margin-left: 0;
          margin-right: 20px;
        }
        &:before {
          display: none;
        }
      }
      &:last-child {
        & span {
          margin-right: 0;
          margin-left: 20px;
        }
      }

    }
  }

  &__desc {
    padding-left: 100px;
    font-size: 14px;
    color: #333333;
  }
}

@media (max-width: 1200px) {
  .header-cart {
    &__way {
      padding: 0;
    }
    &__desc {
      padding-left: 0;
    }
  }
}

@media (max-width: 500px) {
  .cart {
    // overflow-x: hidden;
  }
  .header-cart {
    margin-top: 24px;
    margin-bottom: 16px;
    &__title {
      width: 60%;
    }
    &__text {
      position: relative;
      padding: 10px 0;
      margin-bottom: 22px;
      height: auto;
      box-sizing: border-box;
      &:after {
        content: '';
        display: block;
        width: 300%;
        height: 100%;
        background-color: #F5F5F5;
        position: absolute;
        top: 0;
        left: -100%;
        z-index: -1;
      }
    }
    &__desc {
      padding-left: 0;
    }
    &__way {
      padding: 0;
      overflow: visible;
      margin-bottom: 30px;
      &-title {
        font-size: 18px;
        width: auto;
        &:nth-child(2n + 2) {
          & span {
            margin: 0 16px;
          }
        }
        &:last-child {
          & span {
            margin: 0 16px;
          }
        }

      }
    }
  }
}