
.cart__list {
  padding: 0 100px;

  .cart__item {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 64px;

    &-img {
      width: 385px;
      height: 270px;
      margin-right: 64px;
      object-fit: cover;
    }

    &-content {
      flex-grow: 1;
    }

    &-title {
      color: #1F1F1F;
      margin-bottom: 40px;
      font-size: 3rem;
      line-height: 1;
      font-family: "Playfair Display", serif;
    }

    .cart__desc {
      //width: 200px;
      margin-bottom: 25px;

      &-item {
        display: flex;
        margin-bottom: 8px;
      }

      .desc__title {
        font-size: 14px;
        letter-spacing: 0.01em;
        color: #333333;
        opacity: 0.4;
        width: 100px;
        margin-right: 25px;
      }

      .desc__text {
        font-size: 14px;
      }
    }

    &-price {
      font-size: 24px;
      color: #333333;
    }

    &--close {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      right: 0;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #000000;
        transform: rotate(45deg);
      }

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #000000;
        transform: rotate(-45deg);
      }
    }
  }

  .details {
    position: relative;

    &__info {
      display: block;
      height: 0;
      overflow: hidden;
      transition: height .8s, border .3s;

      &.active {
        display: block;
      }
    }

    &__btn {
      width: 100%;
      height: 32px;
      position: absolute;
      top: 0;
      left: 0;
      border-bottom: 1px solid #333333;

      &:after,
      &:before {
        content: '';
        display: block;
        width: 8px;
        height: 1px;
        background-color: #000000;
        position: absolute;
        right: 0;
        top: 8px;
      }

      &:before {
        transform: rotate(90deg);
      }
    }

    .active {
      &::before {
        display: none;
      }
    }

    &__title {
      font-weight: bold;
      font-size: 14px;
      color: #333333;
      padding-bottom: 18px;
    }

    .info-item {
      padding-top: 46px;
      padding-bottom: 50px;
      border-bottom: 1px solid #E0E0E0;

      &:last-child {
        border-bottom: 1px solid #000000;
      }

      &__wrapper {
        display: flex;
        justify-content: space-between;
      }

      &__name {
        width: 100px;
        font-weight: normal;
        font-size: 18px;
        color: #000000;
        margin-bottom: 12px;
        white-space: nowrap;
      }

      &__size {
        font-weight: normal;
        font-size: 18px;
        color: #000000;
        margin-bottom: 30px;
      }

      &__title {
        font-size: 18px;
        color: #000000;
        margin-bottom: 18px;
      }

      .desc__title {
        font-size: 18px;
        color: #000000;
        opacity: 0.2;
        margin-right: 18px;
      }

      .desc__text {
        font-size: 18px;
      }

      &__content {
        width: 444px;
        height: 299px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        &--cloth {
          width: 282px;
          height: 264px;
        }

        &--wood {
          width: 282px;
          height: 264px;
        }

        &--mechanism {
          width: 444px;
          height: 274px;
        }

        &-img {
          image-rendering: -webkit-optimize-contrast;
          width: 100%;
          height: 100%;
          object-fit: contain;

          &--kit {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      &__category {
        margin-bottom: 14px;
      }

      .active {
        opacity: 1;
        border-color: #000000;
      }

      &__category-item {
        font-size: 18px;
        color: #000000;
        opacity: 0.2;
        border-bottom: 1px solid transparent;
        margin-right: 25px;
        transition: opacity .3s;

        &:hover {
          opacity: 1;
        }
        &:last-child {
          margin-right: 0;
        }
      }

      &__colors {
        &-title {
          font-weight: normal;
          font-size: 18px;
          color: #000000;
          margin-bottom: 30px;
        }

        &-list {
          display: flex;
          flex-wrap: wrap;
          max-width: 100%;
          width: 324px;
        }

        &-item {
          width: 66px;
          height: 66px;
          border-radius: 50%;
          margin-right: 20px;
          margin-bottom: 10px;
          overflow: hidden;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          transition: opacity .3s;

          &:hover {
            opacity: 0.8;
          }
          &:nth-child(4n + 4) {
            margin-right: 0;
          }
        }

        .active {
          border: 1px solid #BFBFBF;
        }

        &-img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
      }

      &__wood-colors {
        &-title {
          font-weight: normal;
          font-size: 18px;
          color: #000000;
          margin-bottom: 30px;
        }

        &-list {
          display: flex;
          flex-wrap: wrap;
          max-width: 100%;
          width: 324px;
        }

        &-item {
          width: 66px;
          height: 66px;
          border-radius: 50%;
          margin-right: 20px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          transition: opacity .3s;
          &:hover {
            opacity: 0.8;
          }

          &:last-child {
            margin-right: 0;
          }
          &:nth-child(4n + 4) {
            margin-right: 0;
          }
        }

        &-img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }

        .active {
          border: 1px solid #BFBFBF;
        }
      }

      .options {
        width: 450px;
        display: flex;
        flex-wrap: wrap;
        align-self: flex-start;
        margin-bottom: 22px;

        &__btn {
          width: 33%;
          height: auto;
          padding: 5px;
          min-height: 50px;
          border: 1px solid #E7E7E7;
          box-sizing: border-box;
          color: #BDBDBD;
          font-size: 18px;
          margin-bottom: -1px;
          border-right: 0;
          transition: color .3s, background-color .3s;

          &:hover {
            color: #000000;
          }

          &:nth-child(3n + 3) {
            border-right: 1px solid #E7E7E7;
          }

          &:last-child {
            border-right: 1px solid #E7E7E7;
          }
        }

        .active {
          color: #000000;
          background-color: #F5F5F5;
        }
      }

      &__content {
        &-img {
          &:first-child {
            display: block;
          }

          display: none;
        }
      }
    }
  }
}

.cart {
  &__documents {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;

    &-wrapper {
      width: 300px;
      display: flex;
      justify-content: space-between;
    }

    &--close {
      // display: none;
      opacity: 0;
      visibility: hidden;
      transition: opacity .3s, visibility .3s;
      position: absolute;
      bottom: -44px;
      right: 0;

      &.active {
        opacity: 1;
        visibility: visible;
      }

    }

    &--pdf {
      font-size: 14px;
      color: #000000;
      display: flex;
      align-items: center;

      & span {
        margin-right: 15px;
      }
    }

    &--print {
      font-size: 14px;
      color: #000000;
      display: flex;
      align-items: center;

      & span {
        margin-right: 15px;
      }
    }
  }

  &__item-wrap {
    margin-bottom: 80px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 100px;

    &-total {
      font-size: 24px;
      color: #000000;
      margin-bottom: 36px;
    }

    &-order {
      width: 242px;
      height: 60px;
      background-color: #202020;
      font-size: 14px;
      color: #FFFFFF;
      transition: background-color 0.3s;
      &:hover {
        background-color: #535252;;
      }
    }
  }

  .btn {
    &__counters {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      width: 150px;
    }
    &__counter {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: rgba(214,214,214, .3);
      font-size: 14px;
      color: #000000;
      &--value {
        text-align: center;
        margin: 0 16px;
        font-size: 14px;
        color: #000000;
        width: 30px;
        cursor: default;
      }
    }
  }
}

.recommend-goods {
  margin-top: 200px;
  margin-bottom: 150px;

  &__title {
    font-family: Playfair Display;
    font-weight: normal;
    font-size: 36px;
    color: #000000;
    margin-bottom: 50px;
  }
}

.recommend-goods {
  .swiper-container {
    overflow: visible;
  }
}

@media (max-width: 1200px) {
  .cart {
    &__list {
      padding: 0;
    }

    &__price {
      padding: 0;
    }
  }
  .recommend-goods {
    margin-top: 100px;
  }
}


@media (max-width: 500px) {
  .recommend-goods {
    margin-top: 130px;

    &__title {
      font-size: 24px;
      margin-bottom: 62px;
    }
  }
  .cart {
    &__price {
      padding: 0;

      &-order {
        width: 100%;
      }
    }

    &__list {
      padding: 0;

      .cart__item {
        padding-top: 50px;
        flex-direction: column;
        margin-bottom: 44px;

        &-price {
          text-align: right;
        }

        &-img {
          max-width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
        }

        .cart__desc {
          width: 100%;
          margin-bottom: 44px;
        }
      }

      .details {
        &__info {
          &.active {
            overflow: visible;
          }
        }

        .info-item {
          &__wrapper {
            flex-direction: column;
          }

          .options {
            width: 100%;

            &__btn {
              width: 50%;
              margin-left: 0;
              border-right: 0;

              &:nth-child(2n + 2) {
                border-right: 1px solid #E7E7E7;
              }

              &:last-child {
                border-right: 1px solid #E7E7E7;
              }

            }
          }

          &__wood-slider {
            overflow: visible;
            margin: 0;
            .swiper-slide {
              width: 70px;
            }
          }

          &__btns {
            margin-bottom: 44px;
          }

          &__content {
            height: 262px;
            width: 100%;
            position: relative;

            &:after {
              content: '';
              width: 300%;
              height: 262px;
              background-color: #f4f4f4;
              position: absolute;
              top: 0;
              left: -100%;
              z-index: -1;
            }

            &-img {
              &--wood {
                width: 100%;
              }
            }

            &--cloth {
              overflow: hidden;
              width: 100%;
              height: 242px;
            }

            &--wood {
              width: 100%;
            }

            &--mechanism {
              width: 100%;
            }
          }

          &__wood-colors {
            margin-bottom: 50px;
          }
        }
      }
    }

    &__documents {
      margin-bottom: 46px;

      &--pdf {
        margin-bottom: 20px;
      }

      &-wrapper {
        flex-direction: column;
      }
    }
  }
}
