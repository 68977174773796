@import "src/assets/style/keyframes";

.header {
  padding-top: 1.875rem;
  padding-bottom: 2.1875rem;
  box-sizing: border-box;
  height: 10vh;
  border-bottom: 1px solid rgba(255, 255, 255, .33);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__wrapper {
    position: relative;
    z-index: 6;
  }

  &__info {
    position: relative;
    transition: background-color .3s;
    z-index: 2;
    margin-left: 20px;


    &:hover {
      background-color: #535252;
    }

    &-list {
      width: 8.75rem;
      border: 1px solid #929292;
      box-sizing: border-box;
      position: absolute;
      top: 2.5rem;
      left: 0;
      transition: opacity .3s, visibility .3s;
      opacity: 0;
      visibility: hidden;

      &:hover {
        opacity: 1;
        visibility: visible;
      }
    }

    &-item {
      width: 100%;
      height: 2.6rem;
      background-color: #535252;
      font-size: 14px;
      color: #FFFFFF;
      border-bottom: 1px solid #929292;
      display: flex;
      justify-content: center;
      align-items: center;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  &--black {
    border-bottom: 1px solid rgba(0, 0, 0, .3);

    & .header__logo-img {
      height: 22px;

      & path {
        fill: #000;
      }
    }

    & .header__item {
      color: #000;
    }

    & .header__item--info::after,
    .header__item--info::before {
      background-color: #000;
    }

    & .header__item-current {
      border: 1px solid rgba(0, 0, 0, .2);
    }

    & .header__burger-line {
      background-color: #000000;

      &:after {
        background-color: #000000;
      }
    }
  }

  @media (max-width: 1200px) {
    height: 12vh;
    padding-top: 0.8125rem;
    padding-bottom: 0.375rem;
    z-index: 6;
  }

  @media (max-width: 500px) {
    height: 91px;
    padding-top: 1.8125rem;

  }

  &__logo {
    width: 8.375rem;
    @media (max-width: 1200px) {
      margin-right: 5.375rem;
    }
    @media (max-width: 500px) {
      margin-right: 2rem;
    }
  }

  &__list {
    @media (max-width: 1200px) {
      display: none;
    }
  }

  &__burger {
    display: none;
    @media (max-width: 1200px) {
      height: 2.5rem;
      width: 2.5rem;
      display: block;
      position: relative;
      &-line {
        display: block;
        width: 100%;
        height: 1px;
        background-color: #F2F2F2;
        position: absolute;
        bottom: 14px;
        left: 0;

        &:after {
          content: '';
          top: -10px;
          left: 0;
          position: absolute;
          display: block;
          width: 100%;
          height: 1px;
          background-color: #F2F2F2;
        }
      }
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
  }
  &__item {
    color: #fff;
    font-size: 0.875rem;
    margin-right: 1.625rem;

    &:last-child {
      margin-right: 0;
    }

    &--info {
      position: relative;
      cursor: pointer;
      padding-right: 0.875rem;
      border: 1px solid #929292;
      width: 8.75rem;
      height: 2.6rem;
      transition: background-color .3s;
      margin-right: 0;
      &:hover {
        & + .header__info-list {
          opacity: 1;
          visibility: visible;
        }
      }

      &::after,
      &::before {
        content: '';
        width: 8px;
        height: 1px;
        background-color: #fff;
        position: absolute;
        top: 50%;
        right: 18px;
      }

      &:before {
        transform: rotate(90deg);
      }

      @media (max-width: 1200px) {
        display: none;
      }
    }

    &--order {
      display: flex;
      align-items: center;
    }

    &-title {
      margin-right: 1.375rem;
      @media (max-width: 1200px) {
        display: none;
      }
    }

    &-current {
      position: relative;
      border: 1px solid rgba(255, 255, 255, .2);
      box-sizing: border-box;
      border-radius: 50%;
      width: 1.875rem;
      height: 1.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.header--white {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
}

.header--black {
  .header__info {
    &:hover {
      background-color: #FFFFFF;
    }
  }

  .header__info-item {
    background-color: #FFFFFF;
    color: #000000;
  }
}


@media (max-width: 1200px) {
  .header {
    position: relative;

    &__menu {
      background-color: #fff;
      position: fixed;
      top: -200%;
      left: 0;
      padding: 0 22px;
      box-sizing: border-box;
      width: 100vw;
      height: 100vh;
      overflow-y: auto;
      z-index: 5;
      opacity: 0;
      //animation: slideDownIn .5s linear;
      transition: top .5s, opacity .5s;

      &.active {
        top: 0;
        opacity: 1;
      }

      &-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 100vw;
        box-sizing: border-box;
        border-bottom: 1px solid #000000;
        height: 12vh;
        padding-top: 0.8125rem;
        padding-bottom: 0.375rem;
      }

      &-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 57px;
        margin-bottom: 30px;
      }

      &-item {
        font-size: 24px;
        font-family: $font-title;
        margin-bottom: 40px;
        color: #000000;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 70px;
        border-top: 1px solid #E6E6E6;
        padding-top: 30px;
      }
      &-contacts {
        display: flex;
        flex-direction: column;
      }
      &-tell,
      &-email {
        font-size: 14px;
        color: #000000;
        margin-bottom: 18px;
      }

      &-email {
        margin-bottom: 0;
      }

      &-title {
        font-size: 18px;
        margin-bottom: 18px;
        white-space: nowrap;
      }

      &-subtitle {
        font-size: 12px;
      }

      .header__logo-img {
        & path {
          fill: #000000;
        }
      }

      .header__item--order {
        color: #000000;
      }

      .header__item-current {
        border: 1px solid rgba(0, 0, 0, .2);
      }

      .header__burger-line {
        background-color: #000000;
        transform: rotate(45deg);
        bottom: 20px;

        &:after {
          background-color: #000000;
          transform: rotate(90deg);
          top: 0;
        }
      }
    }
  }
}