@import '../../../assets/style/root';
@import '../../../assets/style/mixins';


.product {
  padding-top: 5rem;
  padding-bottom: 10rem;
  @extend %cursor-slider;
  &__title {
    text-align: center;
    margin-bottom: 5rem;
    @media (max-width: 500px) {
      margin-bottom: 2.5rem;
      font-size: 2.25rem;
    }
  }
  &__list--mobile {
    display: none;
    @media (max-width: 500px) {
      display: block;
    }
  }
  &__item{
    position: relative;
    @extend %cursor-slider;
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 360px;
      background-color: rgba(0,0,0,.4);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 0;
      transition: opacity .3s;
      @media (max-width: $mw) {
        height: 84%;
      }
    }
    &-link {
      color: #fff;
      font-size: 0.875rem;
      font-family: $font-text;
      position: absolute;
      left: calc(50% - 2.25rem);
      bottom: 4rem;
      opacity: 0;
      visibility: hidden;
      transition: opacity .3s, visibility .3s;
      z-index: 1;
      &:after {
        margin-top: 4px;
        content: '';
        display: block;
        width: auto;
        height: 1px;
        background-color: #fff;
      }
    }
    &-title {
      text-align: left;
      font-family: $font-text;
      font-size: 1.125rem;
      color: #000000;
    }
    &-img {
      width: 30.5rem ;
      height: 22.5rem;
      object-fit: cover;
      box-sizing: border-box;
      margin-bottom: 1.5625rem;
      transition: background-color .3s, opacity .3s;
      position: relative;
      @media (max-width: 500px) {
        width: 20.75rem ;
        max-width: 100%;
        height: 13.75rem;
        margin-bottom: 1.25rem;
      }
    }
    &:hover {
      &:after {
        opacity: 1;
      }
      .product__item-img {
        // background-color: #000;
        // opacity: .9;
      } // почему не сработало &-link
      .product__item-link {
        opacity: 1;
        visibility: visible;
      }
    }
    @media (max-width: 500px) {
     margin-bottom: 3.5rem;
     &:last-child {
       margin-bottom: 0;
     }
    }
  }

  .product__slider {
    overflow: visible;
    transition: transform .3s;
  }
}


@media (max-width: 1200px) {
  .product {
    padding-top: 1.875rem;
    padding-bottom: 3.625rem;
    &-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__item {
      &:focus,
      &:active {
        box-shadow: 0 0 0 0 rgba(0,123,255,0)!important;
      }
      &:after {
        height: 13.75rem;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}