@import "../../assets/style/mixins";

.modal-picture {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 7;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(72, 72, 72, 0.71);
  animation-name: appear;
  animation-duration: 300ms;
  transition: opacity 0.3s, visibility 0.3s;

  &.active {
    display: flex;
  }

  &.active &__inner,
  &__inner-example {
    max-width: 100%;
    position: relative;
    margin: 0 20px;
    max-height: calc(100vh - 40px);
    text-align: center;
    display: block;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    animation-name: slide-in;
    animation-duration: 0.5s;
    overflow: hidden;
    border-radius: 12px;
  }

  &.active &__inner {
    width: auto;
    height: auto;
  }
  
  &.active &__inner-example {
    width: 900px;
    height: auto;
  }

  &__close {
    width: 24px;
    height: 24px;
    @extend %close-btn;
    right: 3%;
    top: 3%;
    &:after,
    &:before {
      height: 2px;
      right: calc(50% - 12px);
    }
  }

  &__picture {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slide-in {
  from {
    transform: translateY(-150px);
  }
  to {
    transform: translateY(0);
  }
}
