.aside-menu {
  max-width: 17.6875rem;
  width: 100%;
  &__list {
    position: sticky;
    top: 40px;
  }
  &__item {
    width: 100%;
    margin-bottom: 1.25rem;
    position: relative;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    &-title {
      font-size: 0.875rem;
      font-weight: 700;
      color: #000000;
    }
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 9px;
      right: 0;
      width: 10px;
      height: 2px;
      background-color: #000000;
      z-index: -1;
    }
    &:after {
      transform: rotate(90deg);
    }

    &.last {
      &:before,
      &:after {
        display: none;
      }
    }
  }
  &__products {
    height: 0;
    opacity: 0;
    margin-top: 15px;
    padding-left: 10px;
    overflow: hidden;
    transition: height .3s, opacity .3s;
    &-item {
      width: 100%;
      font-size: 0.875rem;
      color: #000000;
      padding: 4px 0;
      margin-bottom: 10px;
      text-align: left;
      transition: font-weight .3s;
      &.active {
        font-weight: 700;
      }
    }
  }
}

.minus {
  &:after {
    display: none;
  }
  .aside-menu__products {
    opacity: 1;
  }
}


@media (max-width: 500px) {
  .aside-menu {
    &__item {
      margin-bottom: 0;
    }
  }
}