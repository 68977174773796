@import "src/assets/style/root";

.about-page__people {
  margin-bottom: 140px;
  &-title {
    font-family: $font-title;
    font-weight: normal;
    font-size: 36px;
    text-align: center;
    color: #383C42;
    max-width: 840px;
    margin: 0 auto 90px;
  }
  &-img {
    height: 37.5vw;
    flex-grow: 1;
    margin-right: 64px;
    object-fit: cover;
    &-desc {
      font-size: 18px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      & span {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 700;
        max-width: 70px;
        line-height: 25px;
      }
    }
  }
  &-wrapper {
    display: flex;
    margin-bottom: 140px;
  }

}
.people-desc {
   display: flex;
   justify-content: space-between;
   padding-left: 100px;
   margin-bottom: 100px;
   &__wrapper {
     flex-grow: 1;
     display: flex;
     flex-direction: column;
     justify-content: center;
   }
   &__title {
    font-family: $font-title;
    font-size: 36px;
    margin-bottom: 50px;
   }
   &__text {
     font-size: 18px;
     line-height: 1.5;
     margin-bottom: 26px; 
   }
   &__img {
     width: 408px;
     height: 560px;
     margin-left: 190px;
   }
   &__name {
    font-weight: bold;
    font-size: 14px;
   }
}

.people-slider {
  padding-left: 100px;
  margin-bottom: 80px;
  overflow: visible;
  &__item {
    display: flex;
    padding-right: 140px;
    border-right: 1px solid #E2E2E3;
    width: auto;
    &-img {
      width: 244px;
      height: 337px;
      margin-right: 100px;
      object-fit: cover;
      object-position: top;
    }
    &-name {
      font-size: 36px;
      font-family: Playfair Display;
      margin-bottom: 20px;
    }
    &-position {
      font-size: 14px;
      color: #4F4F4F;
      margin-bottom: 26px;
    }
    &-text {
      font-size: 18px;
      line-height: 1.5;
      width: 465px;
      max-width: 100%;
    }
  }


  .swiper-button-prev,
  .swiper-button-next {
    width: 44px;
    position: absolute;
    right: 0;
    top: calc(100% + 100px);  
    z-index: 2;
    display: flex;
    justify-content: space-between;

    & svg  {
      width: 28px;
      & path {
        fill: #000000;
      }
    }

    &::after {
      content: '';
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #A9AAAD;
      position: absolute;
      top: 6px;
      right: 26px;
    }
  }

  .swiper-button-next {
    transform: rotate(180deg);
    left: calc(50% - 6px);
  }

  .swiper-button-prev {
    left: initial;
    left: calc(50% - 38px);
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 1;

    &::after {
      opacity: 0;
    }
  }

}

.about-page {
  .footer {
    background-color: transparent;
  }
  .video {
    padding: 0 140px ;
    max-width: 100%;
    box-sizing: border-box;
    height: 33.4vw;
    margin: 260px auto 170px;

    & iframe {
      width: 100%;
      height: 100%;
    }
  }
}


@media (max-width: 1200px) {
  .about-page__people {
    &-wrapper {
      flex-direction: column;
    }
    &-img {
      max-width: 100%;
      object-fit: cover;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  .people {
    &-desc {
      padding-left: 0;
      &__img {
        margin-left: 40px;
        width: 360px;
        object-fit: cover;
      }
    }
    &-slider {
      padding-left: 0;
      &__item {
        padding-right: 70px;
        &-img {
          margin-right: 50px;
        }
        &-text {
          width: 400px;
        }
      }
    }
  }
  .about-page {
    .video {
      padding: 0 60px;
    }
  }
}

@media (max-width: 500px) {
  .about-page__people {
    margin-bottom: 90px;
    &-title {
      font-size: 24px;
      margin-bottom: 70px;
      width: 100%;
      line-height: 32px;
    }
    &-wrapper {
      flex-direction: column;
    }
    &-img {
      width: 100%;
      height: 242px;
      margin-right: 0;
      margin-bottom: 20px;
      &-desc {
        font-size: 14px;
      }
    }
    &-desc {
      padding-left: 0;
      flex-direction: column;
    }
  }
  .people-desc {
    &__title {
      font-size: 24px;
      margin-bottom: 20px;
    }
    &__text {
      font-size: 14px;
      color: #000000;
    }
    &__img {
      margin-left: 0;
      padding-left: 50px;
      width: 100%;
      box-sizing: border-box;
      height: 400px;
      margin-bottom: 16px;
    }
    &__name {
      text-align: right;
    }
  }
  .people-slider {
    padding-left: 0;
    &__item {
      flex-direction: column;
      padding-right: 30px;
      max-width: 100%;
      &-img {
        width: 100%;
        height: 374px;
        margin-right: 0;
        margin-bottom: 30px; 
      }
      &-name {
        font-size: 24px;
        margin-bottom: 15px;
      }
      &-position {
        font-size: 14px;
        margin-bottom: 15px;
      }
      &-text {
        font-size: 14px;
        width: 100%;
      }
    }
  }
  .about-page {
    .video {
      padding: 0;
      height: 300px;
      margin-top: 180px;
      margin-bottom: 110px;
    }
  }

}