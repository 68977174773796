@import '../../../assets/style/root';

.social-links {
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;
  @media (max-width: $mw) {
    padding-bottom: 46px;
  }
  &__item {
    cursor: pointer;
    flex-grow: 1;
    height: 16.125rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    margin-right: 20px;
    transition: background-color .3s;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: rgba(147, 147, 147, .2);
    }
    &-title {
      font-family: $font-title;
      font-weight: normal;
      margin-bottom: 4rem;
      color: #1E2227;
      position: relative;
      &:after {
        content: '';
        display: block;
        width: 30px;
        height: 1px;
        background-color: rgba(0,0,0, .1);
        position: absolute;
        left: calc(50% - 15px);
        bottom: -24px;
      }

    }
    &-text {
      max-width: 18.75rem;
      text-align: center;
      line-height: 1.36;
    }
  }
  @media (max-width: $mw) {
    flex-direction: column;
    &__item {
      margin-right: 0;
      margin-bottom: 22px;
      &:last-child {
        margin-bottom: 0;
      }
      &-text {
        font-size: 14px;
      }
    }
  }
}