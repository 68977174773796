@import './../../../assets/style/root.scss';

.goods-card-promo {
  height: 100vh;
  position: relative;

  .swiper-button-prev,
  .swiper-button-next {
    width: 44px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 94vh;
    z-index: 2;
    display: flex;
    justify-content: space-between;

    &::after {
      content: '';
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #808080;
      position: absolute;
      top: 0;
      right: 28px;
    }
  }

  .swiper-button-next {
    transform: rotate(180deg);
    right: 40px;
  }

  .swiper-button-prev {
    left: initial;
    right: 97px;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 1;

    &::after {
      opacity: 0;
    }
  }
}

.goods-card {
  &__slider {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__slider-item {
    position: relative;
    width: 100%;
    height: 100vh;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #000000 -5.78%, rgba(0, 0, 0, 0) 24.61%), linear-gradient(180deg, rgba(0, 0, 0, 0) 58.39%, #000000 112.41%);
    }

    &-img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }

    &-name {
      font-family: Playfair Display;
      font-size: 64px;
      color: #FFFFFF;
      position: absolute;
      bottom: 30px;
      left: calc((100vw - 1460px) / 2);
      z-index: 2;
    }
  }
}

.goods-card__nav {
  // position: absolute;
  // right: 0;
  // padding-right: 40px;
  // bottom: 38px;
  // z-MainPage: 2 !important;
  // width: 168px;
  // display: flex;
  // justify-content: space-between;
}


@media (max-width: 1560px) {
  .goods-card__slider-item-name {
    left: 0;
    padding-left: 40px;
  }
}

@media (max-width: 500px) {
  .goods-card-promo {
    height: 400px;

    .swiper-button-prev,
    .swiper-button-next {
      top: 92%;

      &::after {
        content: '';
        width: 45px;
        height: 45px;
        top: -8px;
        right: 20px;
      }
    }

    .swiper-button-next {
      transform: rotate(180deg);
      right: 40px;
    }

    .swiper-button-prev {
      left: initial;
      right: 64px;
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      opacity: 1;

      &::after {
        opacity: 0;
      }
    }

  }

  .goods-card {
    &__slider {
      height: 400px;
    }

    &__slider-item {
      height: 400px;
      &:after {
        top: 90px;
        height: calc(100% - 90px);
      }

      &-img {
        height: calc(400px - 90px);
        padding-top: 90px;

      }

      &-name {
        font-size: 24px;
        padding-left: 20px;
        bottom: 20px;
      }
    }
  }
}