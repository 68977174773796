@import 'src/assets/style/keyframes';
@import 'src/assets/style/root';
@import 'src/assets/style/mixins';

.preloader {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  top: 0;
  left: 0;

  &.loaded {
    animation: 1s .8s forwards slideDownOut;

    &::after {
      top: 0;
    }
  }

  &::after {
    transition: top .8s;
    position: absolute;
    content: '';
    top: 100%;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: #f3f2f2;
    z-index: 2;
  }

  &__logo {
    width: 12.5rem;
    height: auto;
    animation: fadePulse 2s infinite;
    & path {
      fill: #000000;
    }
  }

}
