@import '../../../assets/style/root';
@import '../../../assets/style/mixins';


.events {
  padding-top: 90px;
  padding-bottom: 90px;
  @media (max-width: 1200px) {
    padding-top: 0.9375rem;
    padding-bottom: 2.75rem;
  }
  &__title {
    text-align: center;
    margin-bottom: 4.0625rem;
  }
  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__item {
    max-width: 100%;
    flex-basis: 24%;
    @media (max-width: 1200px) {
      flex-basis: initial;
    }
    &-img {
      width: 100%;
      height: 16.25rem;
      margin-bottom: 2.5rem;
      object-fit: cover;
    }
    &-text {
      max-width: 100%;
      line-height: 136%;
      color: #000000;
      opacity: 0.4;
    }

    @media (max-width: 1200px) {
      //@at-root: 244px;
      &-img {
        height: 220px;
        margin-bottom: 1.875rem;
      }
      &-text {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .events {
    padding-bottom: 8.8rem;
    &__list {
      overflow: visible;
    }
    &__title {
      margin-bottom: 1.625rem;
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 44px;
      height: 30px;
      position: absolute;
      right: 0;
      top: calc(100% + 100px); // почему не работает bottom 0 
      z-index: 2;
      display: flex;
      justify-content: space-between;

      & svg  {
        width: 33px;
        & path {
          fill: #E0E0E0;
        }
      }

      &::after {
        content: '';
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 1px solid #E0E0E0;
        position: absolute;
        top: -8px;
        right: 22px;
      }
    }

    .swiper-button-next {
      transform: rotate(180deg);
      right: 40px;
    }

    .swiper-button-prev {
      left: initial;
      right: 97px;
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      opacity: 1;

      &::after {
        opacity: 0;
      }
    }
  }
}


.news {
  &__title {
    font-weight: 700;
    color: #000000;
    margin-bottom: 10px;
    font-size: 20px;
  }
}