.order {
  display: flex;
  justify-content: space-between;
  margin-bottom: 150px;
  animation: fadeIn .5s linear;

}

@media (max-width: 500px) {
  .order {
    flex-direction: column;
    margin-bottom: 100px;
  }
}