@import "../../../assets/style/keyframes";


.vacancy-list {
  padding: 0 100px;
  margin-bottom: 110px;
}
.vacancy {
  &__item {
    position: relative;
    margin-bottom: 38px;
    &-title {
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      padding-bottom: 12px;
    }
    &-btn {
      width: 100%;
      height: 39px;
      position: absolute;
      top: 0;
      left: 0;
      border-bottom: 1px solid #333;
      &:before,
      &:after {
        content: '';
        display: block;
        width: 8px;
        height: 1px;
        background-color: #000;
        position: absolute;
        right: 0;
        top: 50%;
      }
      &:before {
        transform: rotate(90deg);
      }
    }
    .active {
      &:before {
        display: none;
      }
      &.vacancy__item-btn + .vacancy__desc {
        display: block;
      } 
    }
    .duty {
      margin-bottom: 30px;
      &__title {
        font-size: 18px;
        color: #333333;
        margin-bottom: 20px;
      }
      &__list {
        display: flex;
        flex-direction: column;
      }
      &__item {
        padding-left: 22px;
        color: #828282;
        margin-bottom: 10px;
        position: relative;
        &:before {
          content: '';
          display: block;
          width: 15px;
          height: 1px;
          background-color: #828282;
          position: absolute;
          left: 0;
          top: 50%;
        }
      }
    }
  }
  &__desc {
    height: 0;
    border-bottom: 1px solid #333333;
    margin-bottom: 34px;
    transition: opacity .4s, visability .3s, height .3s, padding-top .3s;
    // animation: fadeIn 0.3s linear;
    overflow: hidden;
    opacity: 0;
    &-experience {
      display: flex;
      flex-direction: column;
      font-size: 18px;
      color: #000000;
      line-height: 1.7;
      margin-bottom: 45px;
    }
    &-content {
      ul {
        list-style: inside;
      }
    }
  }
  &__submit {
    margin-top: 76px;
    margin-bottom: 45px;
    width: 280px;
    height: 66px;
    background-color: #202020;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1200px) {
  .vacancy-list {
    padding: 0;
  }
}

@media (max-width: 500px) {
  .vacancy-list {
    padding: 0;
  }
  .vacancy__item {
    &-title {
      font-size: 18px;
    }
  }
  .vacancy__submit {
    margin-top: 40px;
    width: 100%;
  }
}