.cookie-alert {
  width: calc(100% - 1px);
  min-height: 150px;
  height: auto;
  background-color: #fff;
  border: 1px solid #AEAEAE;
  padding: 45px 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: fixed;
  left: 1px;
  bottom: 0;
  z-index: 9999999999;
  &__text {
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin-right: 50px;
    max-width: 900px;
  }
  &__btn {
    width: 270px;
    height: 64px;
    background-color: #000000;
    font-size: 18px;
    color: #F9F9F9;
    line-height: 24px;
    padding: 0 20px;
    box-sizing: border-box;
    transition: background-color .3s;
    &:hover {
      background-color: #535252;
    }
  }
}


@media (max-width: 500px) {
  .cookie-alert {
    flex-direction: column;
    padding: 20px;
    &__text {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    &__btn {
      width: 100%;
    }
  }
}