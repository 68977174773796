.vacancy-promo {
  height: 100vh;
  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
  }
  &__img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  &__title {
    font-family: Playfair Display;
    font-size: 64px;
    color: #FFFFFF;
    position: absolute;
    bottom: 30px;
    left: calc((100vw - 1460px) / 2);
  }
}

@media (max-width: 1560px) {
  .vacancy-promo__title {
    left: 0;
    padding-left: 40px;
  }
}

@media (max-width: 500px) {
  .vacancy-promo {
    height: 300px;
    overflow: hidden;
    &__title {
      font-size: 24px;
      padding-left: 20px;
      bottom: 20px;
    }
    &__wrapper {
      height: 300px;
    }
    &__img {
      height: 300px;
      padding-top: 80px;
      box-sizing: border-box;
    }
    .header--black {
      &:after {
        content: '';
        position: absolute;
        width: 300%;
        top: 0;
        left: -100%;
        background-color: #fff;
        height: 80px;
        z-index: -1;
      }
    }
  }
}