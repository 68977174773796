@import "src/assets/style/mixins";

.feedback {
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 24px;
    margin-bottom: 35px;
  }

  &__form {
    display: flex;
    flex-direction: column;

    &-item {
      position: relative;
      margin-bottom: 28px;

      @extend  %inputHover;
    }

    &-label {
      font-size: 18px;
      color: #828282;
      opacity: 0.2;
      position: absolute;
      top: calc(50% - 10px);
      right: 18px;
      transition: opacity 0.3s, color .3s;
      z-index: 1;
    }

    &-input {
      max-width: 100%;
      width: 490px;
      height: 80px;
      border: 1px solid #E0E0E0;
      padding: 0 22px;

      box-sizing: border-box;
      font-size: 18px;
      color: #57595B;
      transition: border .3s, border-color .3s;

      &:focus {
        border: 1px solid #000000;
      }

      &::-webkit-input-placeholder {
        font-size: 18px;
        color: #57595B;
      }

      &:focus + .feedback__form-label {
        opacity: 0;
      }

      &.error {
        border-color: #FF0000;
      }
    }

    &-submit {
      width: 280px;
      height: 66px;
      font-size: 14px;
      margin-top: 20px;
      color: #ffffff;
      background-color: #000000;
      transition: background-color .3s;
      &:hover {
        background-color: #535252;
      }
    }
  }
  &__politic {
    display: flex;
    align-items: center;

    &:hover {
      input[type="checkbox"]:checked + label:before,
      input[type="checkbox"]:not(:checked) + label:before {
        border-color: #000000;
      }
    }

    input[type="checkbox"]:checked + label:before,
    input[type="checkbox"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0px;
      top: calc(50% - 9px);
      border: 1px solid #C4C4C4;
      width: 16px;
      height: 16px;
      cursor: pointer;
      transition: border-color .3s;
    }

    input[type="checkbox"]:checked + label:after,
    input[type="checkbox"]:not(:checked) + label:after {
      content: "";
      position: absolute;
      left: 2px;
      top: 9px;
      width: 10px;
      height: 5px;
      border-radius: 1px;
      border-left: 4px solid #000000;
      border-bottom: 4px solid #000000;
      transform: rotate(-45deg);
    }

    input[type="checkbox"]:not(:checked) + label:after {
      opacity: 0;
    }

    input[type="checkbox"]:checked + label:after {
      opacity: 1;
    }
    &-title {
      position: relative;
      padding-left: 30px;
      line-height: 15px;
      font-size: 12px;
      color: #BDBDBD;
      cursor: pointer;
      max-width: 100%;
      width: 300px;
      transition: color .3s;
      &:hover {
        color: #000000;
      }

      & > a {
        color: #BDBDBD;
        font-size: 12px;
        transition: color .3s;
        &:hover {
          color: #000000;
        }
      }

      &.error {
        color: #FF0000;
        & > a {
          color: #FF0000;
        }
      }
    }

    &.error {
      & label > a {
        color: #FF0000;
      }
      input[type="checkbox"]:not(:checked) + label:before {
        border-color: #FF0000;
      }
    }
  }
}


@media (max-width: 1200px) {
  .feedback {
    width: 100%;

    &__form {
      &-input {
        width: 100%;
        height: 66px;
      }

      &-submit {
        width: 100%;
      }
    }
  }
}

@media (max-width: 500px) {
  .feedback {
    max-width: 100%;
    margin-bottom: 70px;

    &__title {
      font-size: 18px;
      margin-bottom: 25px;
    }

    &__form {
      &-input {
        height: 66px;
        font-size: 14px;
        color: #BDBDBD;
        padding: 0 18px;

        &::-webkit-input-placeholder {
          font-size: 14px;
          color: #BDBDBD;
        }
      }

      &-submit {
        width: 100%;
      }
    }
  }

}