@import "./../../assets/style/root";
@import "./../../assets/style/mixins";

.new-product {
  padding: 3.75rem 0;
  @extend %cursor-slider;
  &__title {
    text-align: center;
    margin-bottom: 50px;
    color: #000;
    @media (max-width: $mw) {
      margin-bottom: 40px;
    }
  }
  &__slider {
    transition: transform 0.3s;
  }

  &__slide-item {
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 16.875rem;
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 0;
      transition: opacity 0.3s;
    }
    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }

  &__item {
    max-width: 100%;
    width: 24.0625rem;
    position: relative;
    @extend %cursor-slider;

    &-title {
      color: #000000;
    }
    &--wrap-img {
      position: relative;
    }
    &-subtitle {
      color: #000000;
      text-decoration: none;
      font-size: 14px;
      margin-bottom: 10px;
      opacity: 0.5;
    }
    &-img {
      width: 100%;
      height: 16.875rem;
      margin-bottom: 25px;
      object-fit: cover;
    }
    &-link {
      color: #fff;
      font-size: 0.875rem;
      font-family: $font-text;
      position: absolute;
      left: calc(50% - 2.25rem);
      bottom: 4rem;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s, visibility 0.3s;
      z-index: 1;
      &:after {
        margin-top: 4px;
        content: "";
        display: block;
        width: auto;
        height: 1px;
        background-color: #fff;
      }
    }
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 16.875rem;
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 0;
      transition: opacity 0.3s;
      @media (max-width: $mw) {
        height: 100%;
      }
    }
    &:hover {
      &:after {
        opacity: 1;
      }
      .new-product__item-link {
        opacity: 1;
        visibility: visible;
      }
    }
    @media (max-width: $mw) {
      width: 20.625rem;
      height: 13.75rem;
      &-img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .new-product__slider {
    overflow: visible;
  }
}

@media (max-width: 500px) {
  .new-product {
    padding-bottom: 11.875rem;

    &__slider {
      overflow: visible;
    }
    &__item {
      &-img {
        margin-bottom: 10px;
      }
      &-title {
        font-size: 14px;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: 44px;
      height: 30px;
      position: absolute;
      right: 0;
      top: calc(100% + 100px); // почему не работает bottom 0
      z-index: 2;
      display: flex;
      justify-content: space-between;

      & svg {
        width: 33px;
        & path {
          fill: #e0e0e0;
        }
      }

      &::after {
        content: "";
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 1px solid #e0e0e0;
        position: absolute;
        top: -8px;
        right: 22px;
      }
    }

    .swiper-button-next {
      transform: rotate(180deg);
      right: 40px;
    }

    .swiper-button-prev {
      left: initial;
      right: 97px;
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      opacity: 1;

      &::after {
        opacity: 0;
      }
    }
  }
}
