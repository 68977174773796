@import "src/assets/style/root";

.cooperation {
  background-color: #F4F4F4;
  //background-image: url('./../../assets/img/mapDealer.png');
  background-position-x: center;
  background-position-y: 170px;
  background-repeat: no-repeat;
  background-size: 90% auto;

  .footer {
    background-color: #F4F4F4;
  }

  &__title {
    margin-top: 100px;
    margin-bottom: 50px;
    max-width: 100%;
    width: 500px;
  }

  &__subtitle,
  &__desc {
    font-size: 18px;
    color: #6D6D6D;
    max-width: 100%;
    width: 500px;
    line-height: 1.5;
  }

  &__subtitle {
    margin-bottom: 18px;
  }

  &__desc {
    margin-bottom: 50px;
  }

  &__sell {
    margin-bottom: 100px;

    &-title {
      font-family: $font-title;
      margin-bottom: 10px;
    }

    &-desc {
      font-size: 18px;
      color: #6D6D6D;
      max-width: 100%;
      width: 500px;
      line-height: 1.5;
    }
  }

  &__terms {
    &-title {
      font-family: $font-title;
      margin-bottom: 24px;
    }

    &-desc {
      font-size: 18px;
      color: #6D6D6D;
      margin-bottom: 50px;
      max-width: 100%;
      width: 500px;
    }

    &-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 150px;
    }
  }

  &__feedback {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-top: 1px solid #000000;
    padding-top: 100px;

    &-title {
      max-width: 100%;
      width: 305px;
      line-height: 85px;
      margin-bottom: 30px;
    }

    &-desc {
      font-size: 18px;
      color: #6D6D6D;
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    margin-top: 170px;

    &-desc {
      font-size: 18px;
      color: #6D6D6D;
      margin-bottom: 22px;
    }

    &-tell,
    &-email {
      font-size: 14px;
      color: #4A4A4A;
      margin-bottom: 6px;
    }

    &-email {
      margin-bottom: 0;
    }
  }
}
.terms__item {
  width: 44%;
  margin-bottom: 50px;

  &-title {
    font-size: 24px;
    margin-bottom: 16px;
  }

  &-desc {
    font-size: 18px;
    color: #6D6D6D;
    line-height: 1.5;
  }
}

@media (max-width: 1200px) {
  .cooperation {
    &__feedback {
      padding-top: 30px;
      &-wrapper {
        margin-bottom: 60px;
      }
      &-title {
        width: 100%;
      }
    }
    &__contacts {
      margin-top: 0;
    }

  }
}


@media (max-width: 500px) {
  .cooperation {
    background-color: #fff;
    background-image: none;

    .footer {
      background-color: #fff;
    }

    &__title {
      font-size: 36px;
      margin-top: 45px;
      margin-bottom: 22px;
    }

    &__subtitle {
      font-size: 14px;
      margin-bottom: 10px;
    }

    &__desc {
      font-size: 14px;
      margin-bottom: 44px;
    }

    &__sell {
      &-title {
        font-size: 24px;
        margin-bottom: 15px;
      }

      &-desc {
        font-size: 14px;
      }
    }

    &__terms {
      &-title {
        margin-top: 40px;
        font-size: 24px;
        margin-bottom: 22px;
      }

      &-desc {
        font-size: 14px;
        color: #6D6D6D;
        margin-bottom: 50px;
      }

      &-list {
        flex-direction: column;
      }
    }

    &__feedback {
      padding-top: 30px;

      &-title {
        font-size: 36px;
        margin-bottom: 24px;
        line-height: 1;
      }

      &-desc {
        font-size: 14px;
        color: #6D6D6D;
        margin-bottom: 35px;
      }
    }

    &__contacts {
      margin-top: 0;
    }
  }
  .terms__item {
    width: 100%;

    &:last-child {
      margin-bottom: 77px;
    }

    &-title {
      font-size: 18px;
    }

    &-desc {
      font-size: 14px;
    }
  }
}