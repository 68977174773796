@import "src/assets/style/root";

.about-page {
  background-color: #F4F4F4;
  overflow: hidden;
  &__promo {
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  &__title {
    max-width: 920px;
    font-family: $font-title;
    font-weight: normal;
    font-size: 36px;
    line-height: 58px;
    color: #383C42;
    text-align: center;
  }
  &__bg {
    width: 235px;
    height: 490px;
    position: absolute;
    top: calc(50% - 245px);
    left: calc(50% - 118px);
  }
  &--arrow {
    height: 38px;
    position: absolute;
    left: calc(50% - 4px);
    bottom: 120px;
  }
}

@media (max-width: 500px) {
  .about-page {
    &__title {
      font-size: 24px;
      line-height: 32px;
    }
    &__bg {
      width: 160px;
      height: 340px;
      top: calc(50% - 170px);
      left: calc(50% - 80px);
    }
  }
}