@import './root';

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes scroll-down {
  0% {
    top: -17px;
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  55% {
    opacity: 0;

  }
  100% {
    top: 100%;
    opacity: 0;
  }
}

@keyframes slideUp {
  0% {
    top: 100%;
  }
  100% {
    top: 0;
  }
}

@keyframes slideDown {
  0% {
    bottom: 100%;
  }
  100% {
    bottom: 0;
  }
}

@keyframes slideDownOut {
  0% {
    top: 0;
  }
  100% {
    top: 100%;
    visibility: hidden;
  }
}

@keyframes slideDownIn {
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
}

@keyframes translateIn {
  100% {
    transform: translate(0);
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(120%);
  }
  100% {
    transform: translateX(0);

  }
}


@keyframes fadePulse {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}