@import "src/assets/style/mixins";

.order {
  .form__person-wrapper {
    margin-bottom: 60px;
  }

  &__form {
    padding-right: 100px;

    .form__persons {
      width: 462px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 54px;

      .active {
        transition: color .5s;
        color: #000;
      }
    }

    .form__person {
      font-size: 24px;
      color: #bdbdbd;
      cursor: pointer;
    }

    .form {
      &__data {
        display: flex;
        flex-direction: column;

        &-title {
          font-size: 18px;
          color: #000000;
          margin-bottom: 28px;
        }

        &-label {
          font-size: 18px;
          color: #828282;
          opacity: 0.2;
          position: absolute;
          top: calc(50% - 10px);
          right: 18px;
          z-index: -1;
          transition: opacity 0.3s;
        }

        &-item {
          position: relative;
          width: 490px;
          margin-bottom: 22px;
        }

        &-input {
          width: 100%;
          height: 60px;
          border: 1px solid #E0E0E0;
          box-sizing: border-box;
          font-size: 18px;
          padding-left: 18px;
          padding-right: 18px;
          text-align: left;
          transition: border .3s;

          @extend  %inputCartHover;

          &.error {
            border: 1px solid #FF0000;
          }

          &::-webkit-input-placeholder {
            color: #BDBDBD;
          }

          &:focus + label {
            opacity: 0;
          }
        }

        &-politic {
          display: flex;
          align-items: center;

          &:hover {
            input[type="checkbox"]:checked + label:before,
            input[type="checkbox"]:not(:checked) + label:before {
              border-color: #000000;
            }
          }

          input[type="checkbox"]:checked + label:before,
          input[type="checkbox"]:not(:checked) + label:before {
            content: "";
            position: absolute;
            left: 0px;
            top: calc(50% - 9px);
            border: 1px solid #C4C4C4;
            width: 16px;
            height: 16px;
            cursor: pointer;
            transition: border-color .3s;
          }

          input[type="checkbox"]:checked + label:after,
          input[type="checkbox"]:not(:checked) + label:after {
            content: "";
            position: absolute;
            left: 2px;
            top: 9px;
            width: 10px;
            height: 5px;
            border-radius: 1px;
            border-left: 4px solid #000000;
            border-bottom: 4px solid #000000;
            transform: rotate(-45deg);
          }

          input[type="checkbox"]:not(:checked) + label:after {
            opacity: 0;
          }

          input[type="checkbox"]:checked + label:after {
            opacity: 1;
          }

        }
      }

      &__politic-title {
        position: relative;
        padding-left: 30px;
        line-height: 15px;
        font-size: 12px;
        color: #BDBDBD;
        cursor: pointer;
        max-width: 100%;
        width: 300px;
        transition: color .3s;
        &:hover {
          color: #000000;
        }

        & > a {
          color: #BDBDBD;
          font-size: 12px;
        }

        &.error {
          color: #FF0000;
          & > a {
            color: #FF0000;
          }
        }
      }


      &__deliverys {
        width: 365px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;

        .active {
          transition: color .5s;
          color: #000;
        }
      }

      &__delivery-title {
        color: #000000;
        font-size: 24px;
        margin-bottom: 30px;
      }

      &__delivery {
        &-content {
          margin-bottom: 70px;
        }

        &-select {
          width: 490px;
          height: 60px;
          padding: 18px 30px 18px 18px;
          box-sizing: border-box;
          border: 1px solid #333333;
          position: relative;
          cursor: pointer;

          //&.active {
          //  &:after {
          //    transform: rotate(-135deg);
          //  }
          //}
        }

        &-city {
          font-size: 18px;
          color: #333333;

          &--btn {
            position: absolute;
            width: 100%;
            height: 60px;
            top: 0;
            left: 0;
          }
        }

        &-dropdown {
          // display: none;
          position: absolute;
          top: 58px;
          left: -1px;
          border: 1px solid #333333;
          width: 490px;
          max-height: 160px;
          overflow-x: hidden;
          overflow-y: auto;
          box-sizing: border-box;
          background-color: #fff;
          z-index: 2;
          opacity: 0;
          visibility: hidden;
          transition: opacity .3s, visibility .3s;

          &.active {
            opacity: 1;
            visibility: visible;
          }
        }

        &-option {
          width: 490px;
          height: auto;
          border-bottom: 1px solid #f5f5f5;
          padding: 18px;
          box-sizing: border-box;
          position: relative;
        }

        &-label {
          font-size: 18px;
          color: #4F4F4F;
        }

        &-map {
          margin-top: 20px;
          margin-bottom: 40px;
          width: 180px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          & span {
            font-weight: bold;
            font-size: 14px;
            color: #4F4F4F;
          }
        }

        &-points {
          margin-bottom: 80px;
        }

        &-point {
          width: 490px;
          height: auto;
          border-bottom: 1px solid #f5f5f5;
          padding: 20px 62px;
          box-sizing: border-box;
          position: relative;

          input[type="radio"]:checked + label:before,
          input[type="radio"]:not(:checked) + label:before {
            content: "";
            position: absolute;
            left: 16px;
            top: calc(50% - 10px);
            border: 1px solid #828282;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            cursor: pointer;
          }

          input[type="radio"]:checked + label:after,
          input[type="radio"]:not(:checked) + label:after {
            content: "";
            position: absolute;
            left: 20px;
            top: calc(50% - 6px);
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #828282;;
          }

          input[type="radio"]:not(:checked) + label:after {
            opacity: 0;
          }

          input[type="radio"]:checked + label:after {
            opacity: 1;
          }

        }
      }

      &__comments {
        &-wrapper {
          display: flex;
          flex-direction: column;
        }

        &-text {
          width: 588px;
          box-sizing: border-box;
          padding: 24px 18px;
          font-size: 18px;
          color: #bdbdbd;
          height: 300px;
          border: 1px solid #CCCCCC;
          margin-bottom: 60px;
          transition: border-color .3s;
          @extend  %commentsHover;

          &::-webkit-input-placeholder {
            transition: color .3s;
            color: #BDBDBD;
          }

          resize: none;
        }

        &-submit {
          width: 280px;
          height: 66px;
          background-color: #202020;
          font-size: 14px;
          color: #fff;
          transition: background-color 0.3s;
          &:hover {
            background-color: #535252;;
          }
        }
      }
    }
  }


  //// style dropdown qutocomplete

  .react-select {
    &__control {
      background-color: transparent;
      border: 0;
      border-radius: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      &:after {
        content: '';
        width: 5px;
        height: 5px;
        border-bottom: 1px solid #000000;
        border-right: 1px solid #000000;
        transform: rotate(45deg);
        position: absolute;
        top: calc(30px - 2.5px);
        right: 25px;
        transition: transform .3s;
      }
      &--menu-is-open {
        &:after {
          transform: rotate(-135deg);
        }
        & + .react-select__menu {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    &__indicators {
      display: none;

    }
    &__value-container {
      padding: 0 0 0 20px;
      font-size: 18px;
    }
    &__menu {
      position: absolute;
      top: 58px;
      left: -1px;
      border: 1px solid #333333;
      width: calc(100% + 2px);
      max-width: calc(100% + 2px);
      max-height: 160px;
      overflow-x: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      background-color: #fff;
      z-index: 2;
      opacity: 0;
      visibility: hidden;
      transition: opacity .3s, visibility .3s;
      margin-top: 0;
      margin-bottom: 0;
      border-radius: 0;
      box-shadow: none;
      animation: fadeIn .3s ease;
      &-list {
        padding: 0;
      }
    }
    &__option {
      cursor: pointer;
      border-bottom: 1px solid #f5f5f5;
      padding: 18px;
      box-sizing: border-box;
      position: relative;
      &:active {
        background-color: transparent;
      }
      &--is-selected {
        background-color: transparent;
        color: #000000;
      }
      &--is-focused {
        background-color: transparent;
        color: #000000;
      }
    }
  }
  .css-1laao21-a11yText {
    display: none;
  }
  .css-1pahdxg-control {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 0;
    height: 100%;
  }
}

@media (max-width: 1200px) {
  .order {
    &__form {
      padding-right: 0;
    }
  }
}

@media (max-width: 768px) {
  .order__form {
    width: 60%;
    .form {
      &__persons  {
        width: 100%;
      }
      &__comments {
        &-text {
          width: 100%;
        }
      }
      &__data {
        &-item {
          width: 100%;
        }
      }
      &__delivery {
        &-select {
          width: 100%;
        }
        &-dropdown {
          width: calc(100% + 2px);
        }
      }
    }

  }
}


@media (max-width: 500px) {
  .order {
    &__form {
      padding-right: 0;
      width: 100%;
      .form {
        &__data {
          margin-top: 40px;

          &-item {
            width: 100%;
          }

          &-input {
            &::-webkit-input-placeholder {
              font-size: 14px;
            }
          }

          &-label {
            font-size: 14px;
          }
        }

        &__delivery-title {
          font-size: 18px;
          margin-bottom: 25px;
          color: #000000;
        }

        &__delivery {
          &-content {
            margin-bottom: 30px;
          }

          &-select {
            width: 100%;
          }

          &-dropdown {
            width: calc(100% + 2px);
          }

          &-option {
            width: 100%;
          }
        }
        &__comments {
          &-text {
            width: 100%;
            height: 200px;
            margin-bottom: 44px;
          }
          &-submit {
            width: 100%;
          }
        }

      }



    }
  }


}
