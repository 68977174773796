@import "src/assets/style/keyframes";

.cart {
  &__orders {
    animation: fadeIn .5s linear;
  }
  &-empty {
    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 70px;
    }
    &__title {
      margin-bottom: 12px;
    }
    &__subtitle {
      font-size: 14px;
      margin-bottom: 75px;
    }
    &__link {
      width: 280px;
      height: 66px;
      background-color: #000000;
      color: #FFFFFF;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media print {
  .preloader {
    display: none;
  }
  .cart {
    overflow: visible !important;
  }
  .header {
    display: none;
  }
  .header-cart {
    display: none;
  }
  .details {
    display: none;
  }
  .cart__list {

    .cart__item {
      margin-bottom: 20px;
    }
    .cart__item-img {
      width: 350px;
      height: 250px;
    }
  }
  .cart__item-wrap {
    margin-bottom: 0;
  }
  .cart__documents {
    display: none;
  }
  .cart__price-order {
    display: none;
  }
  .recommend-goods {
    display: none;
  }
  .footer {
    display: none;
  }
}