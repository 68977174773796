$gen: #000000;
$accent: rgb(4, 0, 255);
$bgc: #f5f5f5;
$text:#333333;
$font-title: 'Playfair Display', serif;
$font-text: 'Inter', sans-serif;
// $cursor-slider: url(./../img/icons/product__cursor.svg), auto;
// $cursor-Cart: url(./../img/icons/cursor-recomend.svg), auto;
$border: #999999; 
$mw: 500px;
$tw: 768px;