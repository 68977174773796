.original-shop {  
  padding-bottom: 100px;
  overflow: hidden;
  &__title {
    margin-bottom: 50px;
  }
  &__slider {
    overflow: visible;
  }
  &__item {
    &-name {
      font-size: 24px;
      color: #000000;
      padding-bottom: 10px;
      border-bottom: 1px solid #BDBDBD;
    }
    &-wrapper {
      margin-top: 35px;
      display: flex;
    }
    &-img {
      width: 275px;
      height: 183px;
      margin-right: 30px;
      object-fit: cover;
    }
    &-text {
      padding-top: 10px;
    }
    &-shop {
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 20px;
    }
    &-address {
      font-size: 14px;
      line-height: 157%;
      color: #4F4F4F;
      margin-bottom: 20px;
    }
    &-tell {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 44px;
    position: absolute;
    right: 0;
    top: calc(100% + 50px);  
    z-index: 2;
    display: flex;
    justify-content: space-between;

    & svg  {
      width: 28px;
      & path {
        fill: #000000;
      }
    }

    &::after {
      content: '';
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #A9AAAD;
      position: absolute;
      top: 6px;
      right: 26px;
    }
  }

  .swiper-button-next {
    transform: rotate(180deg);
    right: 15px;
  }

  .swiper-button-prev {
    left: initial;
    right: 48px;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 1;

    &::after {
      opacity: 0;
    }
  }
}


@media (max-width: 1200px) {
  .original-shop {
    &__item {
      &-wrapper {
        flex-direction: column;
      }
      &-img {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}