.bread-crumbs {
  top: 10vh;
  position: absolute;
  left: 0;
  padding-top: 10px;
  z-index: 2;

  &__list {
    display: flex;
  }

  &__item {
    font-size: 12px;
    color: #C5C2C2;
    margin-right: 14px;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      color: #ffffff;
    }
  }
}

@media (max-width: 500px) {
  .bread-crumbs {
    top: 90px;
    padding-top: 20px;
    z-index: 2;
  }
}