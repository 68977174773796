.auth {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__input {
    border: 1px solid #d6d6d6;
    padding: 15px 17px;
    font-size: 18px;

    &.error {
      margin-bottom: 10px;
      border-color: rgba(255, 0, 0, 0.3);
    }
  }

  .auth__label--error {
    color: rgba(255, 0, 0, 0.7);
  }

  &__field {
    margin-bottom: 15px;
    width: 480px;
    display: flex;
    flex-direction: column-reverse;
  }

  &__title {
    margin-bottom: 50px;
  }

  &__submit {
    background: #202020;
    padding: 25px 28px;
    color: white;
    font-size: 14px;
    width: 100%;
  }

  &__error-log {
   margin-top: 10px;
    color: rgba(255, 0, 0, 0.7);
  }
}

@media (max-width: 768px) {
  .auth {
    &__form {
      width: 80%;
    }

    &__field {
      min-width: none;
      max-width: 100%;
    }
  }
}
